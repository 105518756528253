import '../../../css/Common.scss'
import '../../../css/Buttons.scss'
import { colorDarkGray, globalMargin } from '../Constants'
export function NextButtonIntro({ message, onClick, color1 }: any) {
  return (
    <button
      onClick={onClick}
      style={{ background: color1 }}
      id="start_order"
      className="button_next_intro"
    >
      <span>{message}</span>
      <img
        style={{
          marginLeft: 6,
          width: 5.5,
          height: 9,
        }}
        src="/next_white.png"
        alt=""
      />
    </button>
  )
}

export function NextButtonIntroSimple({ message, onClick, color }: any) {
  return (
    <button
      onClick={onClick}
      style={{
        background: color
      }}
      id="start_order"
      className="button_next_intro_simple"
    >
      <span>{message}</span>
      <img
        style={{
          marginLeft: 6,
          width: 5.5,
          height: 9,
        }}
        src="/next_white.png"
        alt=""
      />
    </button>
  )
}
export function NextButtonProductCount({
  contentWidth,
  buttonDisabled,
  onClickSubmit,
  color1,
  message,
}: any) {
  return (
    <>
      <div className="back-light"></div>
      <button
        disabled={buttonDisabled}
        onClick={onClickSubmit}
        style={
          buttonDisabled
            ? {
              width: contentWidth - 50,
            }
            : {
              width: contentWidth - 50,
              background: color1,
            }
        }
        className="button-next-float"
      >
        <span>{message}</span>
        <img src="/next_white.png" />
      </button>
    </>
  )
}
export function NextButtonProductCountWithPrice({
  contentWidth,
  buttonDisabled,
  onClickSubmit,
  message,
  color1,
}: any) {
  return (
    <>
      <div className="back-light"></div>
      <button
        disabled={buttonDisabled}
        onClick={onClickSubmit}
        style={
          buttonDisabled
            ? {
              width: contentWidth - 50,
            }
            : {
              width: contentWidth - 50,
              background: color1,
            }
        }
        className="button-next-float"
      >
        <span>{message}</span>
        <img src="/next_white.png" />
      </button>
    </>
  )
}

export function NextButtonWithoutPrice({
  contentWidth,
  buttonDisabled,
  onClickSubmit,
  checkMode,
}: any) {
  return (
    <>
      <div className="back-light"></div>
      <button
        disabled={buttonDisabled}
        onClick={onClickSubmit}
        style={

          buttonDisabled
            ? {
              zIndex: 10,
              width: contentWidth - (globalMargin * 2),
            }
            : {
              zIndex: 10,
              width: contentWidth - (globalMargin * 2),
              background: colorDarkGray,
            }
        }
        id={checkMode ? 'button_check' : 'button_next'}
        className="button-next-float"
      >
        <span>{checkMode ? '완료' : '다음'}</span>
        {checkMode ? null : <img src="/next_white.png" />}
      </button>
    </>
  )
}

export function NextButtonWithPrice({
  buttonDisabled,
  onClickSubmit,
  contentWidth,
  checkMode,
  estimatePrice,
}: any) {
  return (
    <>
      <div
        className="back_light"
        style={{
          position: 'fixed',
          width: contentWidth,
          height: '50px',
          left: 0,
          bottom: 50,
        }}
      ></div>
      <div
        style={{
          position: 'fixed',
          background: '#fff',
          width: contentWidth,
          height: '50px',
          left: 0,
          bottom: 0,
        }}
      ></div>
      <button
        disabled={buttonDisabled}
        onClick={onClickSubmit}
        style={
          buttonDisabled
            ? {
              width: contentWidth - (globalMargin * 2),
              position: 'fixed',
              height: 63,
              left: globalMargin,
              bottom: 25,
              zIndex: 3,
            }
            : {
              width: contentWidth - (globalMargin * 2),
              background: colorDarkGray,
              position: 'fixed',
              height: 63,
              left: globalMargin,
              bottom: 25,
              zIndex: 3,
            }
        }
        id={checkMode ? 'button_check' : 'button_next'}
        className="button_next_square_float"
      >
        <div
          id={checkMode ? 'button_check_div' : 'button_next_div'}
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <span className="button_text_main">
            {checkMode ? '완료' : '다음'}
          </span>
          <img
            style={{
              marginLeft: 7,
              alignSelf: 'center',
              display: 'inline-block',
              marginBottom: 1,
              width: 5.5,
              height: 9,
            }}
            src="/next_white.png"
          />
        </div>
        <span className="button_text_sub">
          예상 가격 {estimatePrice.toLocaleString('en-US')}원
        </span>
      </button>
    </>
  )
}

export function NextButtonWithPriceProduct({
  count,
  buttonDisabled,
  onClickSubmit,
  contentWidth,
  color1,
  checkMode,
  estimatePrice,
}: any) {
  return (
    <>{buttonDisabled ?
      <></> :
      <>
        <div
          className="back_light"
          style={{
            position: 'fixed',
            width: contentWidth,
            height: '50px',
            left: 0,
            bottom: 50,
          }}
        ></div>
        <div
          style={{
            position: 'fixed',
            background: '#fff',
            width: contentWidth,
            height: '50px',
            left: 0,
            bottom: 0,
          }}
        ></div>
        <button
          disabled={buttonDisabled}
          onClick={onClickSubmit}
          style={
            buttonDisabled
              ? {
                width: contentWidth - (globalMargin * 2),
                position: 'fixed',
                height: 63,
                left: globalMargin,
                bottom: 25,
                zIndex: 3,
              }
              : {
                width: contentWidth - (globalMargin * 2),
                background: color1,
                position: 'fixed',
                height: 63,
                left: globalMargin,
                bottom: 25,
                zIndex: 3,
              }
          }
          id={checkMode ? 'button_check' : 'button_next'}
          className="button_next_square_float"
        >
          <div
            id={checkMode ? 'button_check_div' : 'button_next_div'}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <span className="button_text_main">
              {checkMode ? '완료' : '다음'}
            </span>
            <img
              style={{
                marginLeft: 7,
                alignSelf: 'center',
                display: 'inline-block',
                marginBottom: 1,
                width: 5.5,
                height: 9,
              }}
              src="/next_white.png"
            />
          </div>
          <span className="button_text_sub">
            상품 {count}개 담음 {estimatePrice.toLocaleString('en-US')}원
          </span>
        </button>
      </>
    }
    </>
  )
}

export function FinishButtonWithPrice({
  buttonDisabled,
  onClickSubmit,
  contentWidth,
  estimatePrice,
}: any) {
  return (
    <div
      style={{
        position: 'absolute',
        zIndex: 2,
      }}
    >
      <div
        className="back_light"
        style={{
          position: 'fixed',
          width: contentWidth,
          height: '50px',
          left: 0,
          bottom: 50,
        }}
      ></div>
      <div
        style={{
          position: 'fixed',
          background: '#fff',
          width: contentWidth,
          height: '50px',
          left: 0,
          bottom: 0,
        }}
      ></div>
      <button
        disabled={buttonDisabled}
        onClick={onClickSubmit}
        style={
          buttonDisabled
            ? {
              width: contentWidth - 50,
              position: 'fixed',
            }
            : {
              width: contentWidth - 50,
              background: colorDarkGray,
              position: 'fixed',
            }
        }
        id="button_finish"
        className="button_next_square_float"
      >
        <p id="button_finish_p" className="button_text_main">
          {'작성완료'}
        </p>
        <span id="button_finish_span" className="button_text_sub">
          예상 가격 {estimatePrice.toLocaleString('en-US')}원
        </span>
      </button>
    </div>
  )
}
