import { getOrderSheetsByShop, getShop } from '../../../api'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import ProtectedPage from '../../../components/ProtectedPage'
import { Dayjs } from 'dayjs'
import { useQuery } from '@tanstack/react-query'
import CalendarAdmin from '../../../components/ShopAdmin/Calendar/CalendarAdmin'
import GeneralAdmin from '../../../components/ShopAdmin/MainPage/GeneralAdmin'
import OrderSheetToggle from '../../../components/ShopAdmin/MainPage/OrderSheetToggle'
import SoldOutSettingNavButton from '../../../components/ShopAdmin/MainPage/SoldOutSettingNavButton'

interface ShopAdminBaseProps {
  orderType?: string
}

export function Base(props: ShopAdminBaseProps) {
  let storage = sessionStorage
  const [contentWidth, setContentWidth] = useState(375)
  useEffect(() => {
    if (!isContentWidthSet) {
      if (window.innerWidth < 600) {
        // 처음에 한번만 width를 세팅하도록 한다.
        setIsContentWidthSet(true)
        setContentWidth(window.innerWidth)
        storage.setItem('contentWidth', window.innerWidth.toString())
      } else {
        setIsContentWidthSet(true)
        storage.setItem('contentWidth', '400')
        setContentWidth(window.innerWidth)
      }
    }
  }, [])

  const { shopUuid, shopName } = useParams()

  const [isContentWidthSet, setIsContentWidthSet] = useState(false)

  const dayjs = require('dayjs')
  const timezone = require('dayjs/plugin/timezone')
  const utc = require('dayjs/plugin/utc')

  dayjs.extend(utc)
  dayjs.extend(timezone)
  dayjs.tz.setDefault('Asia/Seoul')

  const shopQuery = useQuery(['shop', shopUuid], getShop)

  const orderSheetQuery = useQuery([shopUuid], getOrderSheetsByShop)

  const [blockedDay, setBlockedDay] = useState([])

  // 초깃값들 날짜는 Format해서 Time은 Dayjs로 받는다.
  const [blockedDatesFormated, setBlockDatesFormated] = useState<string[]>([])
  const [blockedTimes, setBlockedTimes] = useState<Dayjs[]>([])

  const [showOrderSheetToggle, setShowOrderSheetToggle] = useState(false)
  const [information, setInformation] = useState<any>()
  useEffect(() => {
    if (orderSheetQuery.data) {
      const orderTypeQueryData = orderSheetQuery.data.find(
        (item: { order_type: string }) => item.order_type === props.orderType,
      )
      // block 된 요일 세팅 요일은 1 : 월 2 : 화 순으로 number[]로 백엔드에서 넘어온다.
      setBlockedDay(orderTypeQueryData.blocked_day)
      let draftBlockDates: any = []
      const blockDates: string[] = orderTypeQueryData.blocked_dates
      // 날짜와 시간을 set한다. 시간은 Dayjs 날짜는 MM:DD 로 포멧된 상태로 받는다.
      // 시간은 날짜를 선택한 순간에 해당 날짜에 대응하는 시간들만 포멧된 상태로 수정한다.
      blockDates.forEach((date: any) => {
        var dateSplit = date.blocked_date.split('-')
        const blockedDate = dayjs()
          .tz()
          .set('year', dateSplit[0])
          .set('month', Number(dateSplit[1]) - 1)
          .set('date', dateSplit[2])
          .set('hour', 0)
        draftBlockDates.push(blockedDate.format('MM:DD'))
      })

      const startTime = orderTypeQueryData.start_time
      const endTime = orderTypeQueryData.end_time

      let draftBlockTimes: Dayjs[] = []
      const blockTimes: any[] = orderTypeQueryData.blocked_times
      blockTimes.forEach((time: any) => {
        var timeParsed = dayjs.tz(time.blocked_time)
        draftBlockTimes.push(timeParsed)
      })

      draftBlockTimes = draftBlockTimes.filter(timeParsed => {
        const timeString = timeParsed.format('HH:mm:ss')
        return timeString >= startTime && timeString <= endTime
      })

      storage.setItem(
        'information',
        JSON.stringify(orderTypeQueryData.information),
      )
      setInformation(orderTypeQueryData.information)
      storage.setItem('orderPk', orderTypeQueryData.id)
      storage.setItem('orderType', orderTypeQueryData.order_type)
      storage.setItem('blockedTime', JSON.stringify(draftBlockTimes))
      storage.setItem('blockedDates', JSON.stringify(draftBlockDates))
      storage.setItem('color1', orderTypeQueryData.color_1)
      storage.setItem('color2', orderTypeQueryData.color_2)
      storage.setItem('color3', orderTypeQueryData.color_3)
      storage.setItem('startTime', startTime)
      storage.setItem('endTime', endTime)
      storage.setItem(
        'startDate',
        JSON.stringify(orderTypeQueryData.start_date),
      )
      storage.setItem('endDate', JSON.stringify(orderTypeQueryData.end_date))
      storage.setItem(
        'blockedDay',
        JSON.stringify(orderTypeQueryData.blocked_day),
      )
      storage.setItem('products', JSON.stringify(orderTypeQueryData.products))

      setBlockDatesFormated(draftBlockDates)
      setBlockedTimes(draftBlockTimes)

      if (
        orderSheetQuery.data.length === 2 &&
        orderSheetQuery.data.some(
          (item: { order_type: string }) => item.order_type === 'P',
        ) &&
        orderSheetQuery.data.some(
          (item: { order_type: string }) => item.order_type === 'C',
        )
      ) {
        setShowOrderSheetToggle(true)
      }
    }
  }, [orderSheetQuery.data, orderSheetQuery.isRefetching])

  const nav = useNavigate()

  const orderPk = storage.getItem('orderPk')
  const startDate = JSON.parse(storage.getItem('startDate') as string)
  const endDate = JSON.parse(storage.getItem('endDate') as string)

  const onClickTargetDay = (clickedDay: Dayjs, blocked: boolean) => {
    storage.setItem('currentDate', clickedDay.toString())
    storage.setItem('currentBlocked', blocked.toString())
    nav(`/shopAdmin/${shopUuid}/${shopName}/${orderPk}/Time`)
  }

  const lastSelectedDay = dayjs(storage.getItem('lastSelectedDay'))

  const productsString = storage.getItem('products')
  const products = productsString ? JSON.parse(productsString) : []

  return (
    <ProtectedPage shopQuery={shopQuery} shopUuid={shopUuid}>
      <div
        style={{
          width: contentWidth,
          textAlign: 'center' as 'center',
        }}
      >
        <div>
          {showOrderSheetToggle ? (
            <OrderSheetToggle
              shopUuid={shopUuid}
              shopName={shopName}
              orderType={props.orderType}
            />
          ) : null}
        </div>
        <div>
          <SoldOutSettingNavButton
            products={products}
            shopUuid={shopUuid}
            shopName={shopName}
          />
        </div>
        <CalendarAdmin
          lastSelectedDay={lastSelectedDay}
          onClickTargetDay={onClickTargetDay}
          blockedDay={blockedDay}
          blockedDatesFormated={blockedDatesFormated}
          contentWidth={contentWidth}
          blockedTimes={blockedTimes}
          information={information}
          startDate={startDate}
          endDate={endDate}
        />
        <GeneralAdmin shopName={shopName} blockedDay={blockedDay} />
      </div>
    </ProtectedPage>
  )
}
