import { memo } from 'react'
import { CalculateProductGroupMinPrice } from '../../utility/utils'
import { colorGray } from '../Constants'
import DesignGrid from './DesignGrid'

const DesignGroupGrid = ({
  refOfDesignGroup,
  productGroupName,
  products,
  contentWidth,
  checkIsSelected,
}: any) => {
  return (
    <div key={productGroupName} ref={refOfDesignGroup} id={productGroupName}>
      <div className="design-group-title">
        <div>{productGroupName}</div>
        <span style={{ color: colorGray }}>
          {`${CalculateProductGroupMinPrice(productGroupName).toLocaleString(
            'en-us',
          )}원 부터~`}
        </span>
      </div>
      <DesignGrid
        contentWidth={contentWidth}
        products={products}
        productGroupName={productGroupName}
        checkIsSelected={checkIsSelected}
      />
    </div>
  )
}
export default memo(DesignGroupGrid)
