import ProgressBar from '../PageElement/ProgressBar'
import { Option } from '../type'
import { useState, useEffect } from 'react'
import { CalculatePrice } from '../../utility/utils'
import '../../../css/SelectNumber.scss'

export default function InputLayout({
  optionType,
  options,
  selectedOption,
  setSelectedOption,
  setButtonDisabled,
  setEstimatePrice,
  placeHolder,
  isMandatory,
  progressOrder,
  priceUnit,
  content,
  setContent,
}: any) {
  const storage = sessionStorage
  const contentWidth = Number(storage.getItem('contentWidth'))
  const checkMode = storage.getItem('checkMode')

  let originPrice = CalculatePrice(optionType)
  const [contentLength, setContentLength] = useState(0)
  const [maxLength, setMaxLength] = useState(0)
  const [instructionMessage, setInstructionMessage] = useState('')

  let unitWord = priceUnit === 1000 ? '천원' : '만원'

  const getInstructionMessage = (options: any) => {
    let optionWithoutZero = [...options]
    optionWithoutZero.splice(0, 1)
    let message = optionWithoutZero.reduce(
      (acc: any, cur: any) =>
        acc + `${cur.name} ${cur.value / priceUnit}${unitWord}, `,
      '',
    )
    message = message.replace(/,\s*$/, '')
    return message
  }
  useEffect(() => {
    if (options && options[0] && options[0].option_type === optionType) {
      let maxLength = options[options.length - 1].information.letter_range[1]
      setMaxLength(maxLength - 1)
      setInstructionMessage(getInstructionMessage(options))
    }
  }, [options])

  const FindOptionByLength = (count: number) => {
    let optionSelected = options[0]
    options.forEach((option: Option) => {
      const [startCount, EndCount] = option.information.letter_range
      if (startCount <= count && count <= EndCount) {
        optionSelected = option
      }
    })
    return optionSelected
  }
  const onChangeContent = (event: any) => {
    const countWithoutJump = event.target.value.replace(/\s/g, '').length
    if (countWithoutJump <= maxLength) {
      setContentLength(countWithoutJump)
      setContent(event.target.value)
      setSelectedOption(FindOptionByLength(countWithoutJump))
    }
  }

  useEffect(() => {
    if (selectedOption) {
      setEstimatePrice(originPrice + selectedOption.value)
      if (!isMandatory) {
        setButtonDisabled(false)
      } else {
        if (selectedOption.value !== 0) {
          setButtonDisabled(false)
        } else {
          setButtonDisabled(true)
        }
      }
    }
  }, [selectedOption])

  return (
    <div
      style={{
        width: contentWidth,
        textAlign: 'center',
      }}
    >
      <ProgressBar
        checkMode={checkMode}
        contentWidth={contentWidth}
        orderIndex={progressOrder}
      />

      <input
        className="number-input"
        type="text"
        placeholder={placeHolder}
        value={content}
        onChange={onChangeContent}
      />
      <div
        style={{
          marginLeft: 25,
          textAlign: 'left',
          marginTop: '12px',
          display: 'flex',
          width: contentWidth - 50,
          alignItems: 'start',
        }}
      >
        <span className="instruction-message">{instructionMessage}</span>
        <div
          style={{
            display: 'flex',
            textAlign: 'start',
            whiteSpace: 'nowrap',
          }}
        >
          <span className="letter-count-main">{contentLength}자</span>
          <span className="letter-count-sub">/ {maxLength}자</span>
        </div>
      </div>
    </div>
  )
}
