import { useNavigate, useParams } from 'react-router-dom'
import '../../css/Intro.scss'
import {
  NextButtonIntroSimple,
} from '../../components/OrderSequence/PageElement/NextButton'
import { useState } from 'react'
import { Loading } from '../../components/OrderSequence/PageLayout/Loading'
import { colorCharcoal } from '../../components/OrderSequence/Constants'


const ToKakao = ({ onClickToKakao }: any) => {
  const message = "다른 상품 주문을 원한다면?"
  return (
    <div className="to-kakao-box" onClick={onClickToKakao}>
      <div>{message}</div>
      <div>
        <img src="/kakao.png" />
        <p>카톡상담 바로가기</p>
      </div>
    </div>
  )
}
export default function Intro({ shopData, orderData, color1 }: any) {
  let storage = sessionStorage
  const contentWidth = Number(storage.getItem('contentWidth'))

  const orderType = storage.getItem('orderType')

  const [isLoading, setIsLoading] = useState(true)
  const onLoad = () => {
    setIsLoading(false)
  }
  const outLink = storage.getItem('outLink')
  const onClickToKakao = () => {
    if (outLink) {
      window.location.assign(outLink)
    } else {
      throw new Error("카카오 링크가 존재하지 않습니다.")
    }
  }
  const nav = useNavigate()
  const { shopName, orderPk } = useParams()
  const onClickStartOrder = () => {
    nav(`/order/${shopName}/${orderPk}/Date`)
  }

  const checkKakaoBackDoor = () => {
    const information = JSON.parse(storage.getItem('information') as string)
    if (information && 'kakaoBackDoor' in information) {
      if (information.kakaoBackDoor == 'true' || information.kakaoBackDoor == 'True') {
        return true
      } else {
        return false
      }
    } else {
      return true
    }
  }

  return (
    <div>
      {isLoading ? (
        <Loading
          height={window.innerHeight}
          width={contentWidth}
          message={'주문서를 가져오는 중입니다_잠시만 기다려주세요!'}
        />
      ) : null}
      <div

        style={{
          width: contentWidth,
        }}
      >
        <div
          onLoad={onLoad}
          className={"intro-img"} style={{ width: contentWidth }}>
          {orderData ? <img
            src={orderData.main_img} /> : null}

        </div>
        <div
          className={"title-group-intro"}>
          {shopData && orderData ? (
            <>
              <div className="main">{shopData.name_kr}</div>
              {orderData.subtitle.includes('_') ? (
                <div className="sub">
                  {orderData.subtitle.split('_').map((textline: string) => (
                    <div key={textline}>{textline}</div>
                  ))}
                </div>
              ) : (
                <div className="sub">{orderData.subtitle}</div>
              )}
            </>
          ) : null}
        </div>
        <NextButtonIntroSimple message="주문하러 가기" color={colorCharcoal} onClick={onClickStartOrder} />
        {checkKakaoBackDoor() ?
          <ToKakao
            orderType={orderType}
            onClickToKakao={onClickToKakao} />
          : null}
      </div >
    </div >
  )
}
