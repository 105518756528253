import { createRef, useCallback, useEffect, useState, RefObject } from "react";
import { MenuGroupCarousel } from "../PageElement/MenuCarousel"
import DesignGroupGrid from "../PageLayout/DesignGroupGrid"
import { Product } from "../type";
import { useLocation } from "react-router-dom";
interface ProductGroupProps {
    contentWidth: number
    products: Product[]
    productSelected: Product
}
export default function ProductGroup({ contentWidth, products, productSelected }: ProductGroupProps) {
    useEffect(() => {
        if (products && productSelected) {
            if (productSelected) {
                setSelectedGroupName(productSelected.product_group)
            }
        }
    }, [products, productSelected])

    const [scrollMoving, setScrollMoving] = useState(false);
    // Group 별로 컨트롤 하기 위한 딕셔너리
    const [productGrouped, setProductGrouped] = useState<{ [key: string]: Product[] }>()
    // AutoScroll을 위한 Ref 리스트
    const [elRefs, setElRefs] = useState<{ [key: string]: RefObject<HTMLDivElement> }>();
    const options: IntersectionObserverInit = {
        root: null,
        threshold: 0.5,
    }

    const interSectionCallback: IntersectionObserverCallback = (entries: IntersectionObserverEntry[], observer) => {
        entries.forEach((entry) => {
            const { isIntersecting } = entry;
            // 관찰 요소와 교차될때마다 index를 계산하여 viewIndex의 값을 바꿔준다.
            if (isIntersecting && !scrollMoving) {
                setSelectedGroupName(entry.target.getAttribute('id') as string)
            }
        })
    }
    const io = new IntersectionObserver(interSectionCallback, options);
    //Intersection Observer
    useEffect(() => {
        if (elRefs && Object.keys(elRefs).length !== 0) {
            Object.keys(elRefs).forEach((key: any) => {
                if (elRefs[key]) {
                    io.observe(elRefs[key].current!)
                }
            })
        }
    }, [elRefs, options])
    useEffect(() => {
        if (products) {
            let productsGroupedTmp = products.reduce((acc: any, curr) => {  // [1]
                const key = curr.product_group;                        // [2]
                if (acc[key]) acc[key].push(curr);
                else acc[key] = [curr];
                return acc;
            }, {});
            setProductGrouped(productsGroupedTmp)
            let refArray: any = {}
            Object.keys(productsGroupedTmp).map((productGroupName: string) => {
                refArray[productGroupName] = createRef()
            })
            setElRefs(refArray)
        }
    }, [products])

    const focusToSelectedGroup = (groupName: any) => {
        setScrollMoving(true)
        if (elRefs) {
            Object.keys(elRefs).forEach((key: string) => {
                if (key === groupName) {
                    let refCur: any = elRefs[key].current
                    if (refCur) {
                        let scrollPosition = refCur.offsetTop - 40
                        window.scrollTo({ top: scrollPosition, behavior: "smooth" as ScrollBehavior })
                    }
                }
            })
        }
        setSelectedGroupName(groupName)
        setScrollMoving(false)
    }

    const [selectedGroupName, setSelectedGroupName] = useState("");
    const checkGroupSelected = (name: string) => {
        return (name === selectedGroupName)
    }
    const onClickCarouselButton = (groupName: string) => {
        setSelectedGroupName(groupName)
        if (elRefs) {
            focusToSelectedGroup(groupName)
        }


    }
    const location = useLocation()
    const onLoadMain = () => {
        if (location.state && location.state.prevPath === "DesignDetail") {
            let lastPosition = Number(sessionStorage.getItem('lastPosition'))
            window.scrollTo({ top: lastPosition, behavior: "instant" as ScrollBehavior })
            window.history.replaceState({}, document.title)
        }
        else {
            if (productSelected) {
                focusToSelectedGroup(productSelected.product_group)
            }
        }
    }
    return (<><MenuGroupCarousel
        productGrouped={productGrouped}
        checkGroupSelected={checkGroupSelected}
        onClickCarouselButton={onClickCarouselButton}
        contentWidth={contentWidth}
    />
        <div
            onLoad={onLoadMain}
            style={{ marginTop: 115, marginBottom: 150 }}>
            {productGrouped && elRefs ? Object.keys(productGrouped).map(function (productGroupName) {
                return (
                    <DesignGroupGrid
                        key={productGroupName}
                        refOfDesignGroup={elRefs[productGroupName]}
                        productGroupName={productGroupName}
                        products={productGrouped[productGroupName]}
                        contentWidth={contentWidth}
                    />
                )
            }) : null}
        </div>
    </>)
}