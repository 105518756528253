import { createBrowserRouter } from 'react-router-dom'
import Home from './routes/ShopAdmin/LoginPage/Home'
import Cake from './routes/ShopAdmin/MainPage/Cake'
import CalendarPickupOptions from './routes/ShopAdmin/Calendar/CalendarPickupOptions'
import Login from './routes/ShopAdmin/LoginPage/Login'
import Root from './components/Root'
import NotFound from './routes/NotFound'
import Order from './routes/Order'
import CallBack from './routes/CallBack'
import SelectDate from './routes/OrderSequence/SelectDate'
import SelectTime from './routes/OrderSequence/SelectTime'
import UserInfo from './routes/OrderSequence/UserInfo'
import OrderCheck from './routes/OrderSequence/OrderCheck'
import ToKakao from './routes/OrderSequence/ToKakao'
import PickupSettings from './routes/ShopAdmin/SettingPages/PickupSettings'
import ClipboardCopyFailed from './routes/OrderSequence/ClipboardCopyFailed'
import Product from './routes/ShopAdmin/MainPage/Product'
import SoldOutSettings from './routes/ShopAdmin/SettingPages/SoldOutSettings'
import ProductDeatil from './routes/OrderSequence/Product/ProductDetail'
import UploadImage from './routes/UploadImage'
import Select from './routes/OrderSequence/Select'
import SelectProduct from './routes/OrderSequence/Product/SelectProduct'
import GPT from './routes/GPT'
const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <NotFound />,
    children: [
      {
        path: '',
        element: <Home />,
      },
      {
        path: 'shopAdminHome',
        element: <Home />,
      },
      {
        path: 'shopAdminLogin',
        element: <Login />,
      },
      {
        path: 'shopAdmin/:shopUuid/:shopName/C',
        element: <Cake />,
      },
      {
        path: 'shopAdmin/:shopUuid/:shopName/P',
        element: <Product />,
      },
      {
        path: 'shopAdmin/:shopUuid/:shopName/P/soldOutAdmin',
        element: <SoldOutSettings />,
      },
      {
        path: 'shopAdmin/:shopUuid/:shopName/:orderPk/Time',
        element: <CalendarPickupOptions />,
      },
      {
        path: 'shopAdmin/:shopUuid/:shopName/:orderPk/PickupTime',
        element: <PickupSettings />,
      },
      {
        path: 'order/:shopName/:orderPk',
        element: <Order />,
      },
      {
        path: 'order/:shopName/:orderPk/Date',
        element: <SelectDate />,
      },
      {
        path: 'order/:shopName/:orderPk/Time',
        element: <SelectTime />,
      },
      {
        path: 'order/:shopName/:orderPk/product/:viewType',
        element: <SelectProduct />,
      },
      {
        path: 'order/:shopName/:orderPk/productDetail/:productPk',
        element: <ProductDeatil />,
      },
      {
        path: 'order/:shopName/:orderPk/:optionType/:viewType',
        element: <Select />,
      },
      {
        path: 'order/:shopName/:orderPk/User/Info',
        element: <UserInfo />,
      },
      {
        path: 'order/:shopName/:orderPk/Check',
        element: <OrderCheck />,
      },
      {
        path: 'order/:shopName/:orderPk/ToKakao',
        element: <ToKakao />,
      },
      {
        path: 'order/:shopName/:orderPk/ClipboardCopyFailed',
        element: <ClipboardCopyFailed />,
      },
      {
        path: 'wonyoung4go',
        element: <GPT />,
      },

      {
        path: 'callback',
        element: <CallBack />,
      },
    ],
  },
])
export default router
