import { useState, useEffect } from 'react'
import {
  CalculatePrice,
  FilterSortOption,
  getCurrentPage,
  getNextPage,
  getPageByOptionType,
} from '../../components/utility/utils'
import VerticalChoiceLayout from '../../components/OrderSequence/PageLayout/VerticalChoice'
import { useNavigate, useParams } from 'react-router-dom'
import ProgressBar from '../../components/OrderSequence/PageElement/ProgressBar'
import { Option } from '../../components/OrderSequence/type'
import {
  NextButtonWithPrice,
  NextButtonWithoutPrice,
} from '../../components/OrderSequence/PageElement/NextButton'
import InputLayout from '../../components/OrderSequence/PageLayout/InputLayout'
import { Title } from '../../components/OrderSequence/Format'
import CustomInput from '../../components/OrderSequence/PageElement/CustomInput'

export default function Select() {
  let storage = sessionStorage
  const { optionType, viewType } = useParams()

  const currentPage = getCurrentPage(optionType as string)
  const nextPage = getNextPage(currentPage)

  const contentWidth = Number(storage.getItem('contentWidth'))

  let optionData = JSON.parse(storage.getItem('options') as string)
  let title = currentPage.title

  const [options, setOptions] = useState<any[]>([])
  useEffect(() => {
    const tempSort = FilterSortOption(optionData, optionType as string)

    setOptions(tempSort)
  }, [optionType])
  const checkMode = storage.getItem('checkMode') as string
  let originPrice = CalculatePrice(optionType as string)

  const [estimatePrice, setEstimatePrice] = useState(originPrice)
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const nav = useNavigate()
  const { shopName, orderPk } = useParams()
  const [selectedOption, setSelectedOption] = useState<Option>()
  const [content, setContent] = useState('')

  useEffect(() => {
    if (options.length !== 0) {
      let tmp: Option = JSON.parse(
        storage.getItem(optionType as string) as string,
      )
      if (tmp) {
        setSelectedOption(tmp)
        setEstimatePrice(estimatePrice + tmp.value)
      } else {
        setSelectedOption(options[0])
        setEstimatePrice(estimatePrice + options[0].value)
      }
    }

    let contentInStorage = storage.getItem(`${optionType}Content`) as string
    if (contentInStorage) {
      setContent(contentInStorage)
    } else {
      setContent('')
    }
    setButtonDisabled(false)
  }, [options])

  useEffect(() => {
    if (!currentPage.mandatory) {
      setButtonDisabled(false)
    }
  }, [currentPage])

  const onClickSubmitInput = () => {
    if (selectedOption) {
      storage.setItem(optionType as string, JSON.stringify(selectedOption))
      storage.setItem(`${optionType}Content`, content)
      if (checkMode) {
        nav(`/order/${shopName}/${orderPk}/Check`)
      } else {
        if (nextPage) {
          nav(
            `/order/${shopName}/${orderPk}/${nextPage.option_type}/${nextPage.view_type}`,
          )
        } else {
          nav(`/order/${shopName}/${orderPk}/${nextPage.option_type}/`)
        }
      }
    }
  }
  const onClickSubmitChoice = () => {
    if (selectedOption) {
      storage.setItem(optionType as string, JSON.stringify(selectedOption))
      if (checkMode) {
        nav(`/order/${shopName}/${orderPk}/Check`)
      } else {
        if (
          selectedOption.information &&
          'next_point' in selectedOption.information
        ) {
          const nextOptionType = selectedOption.information.next_point
          const nextPageByOptionType = getPageByOptionType(nextOptionType)
          nav(
            `/order/${shopName}/${orderPk}/${nextPageByOptionType.option_type}/${nextPageByOptionType.view_type}`,
          )
        } else if (nextPage) {
          nav(
            `/order/${shopName}/${orderPk}/${nextPage.option_type}/${nextPage.view_type}`,
          )
        } else {
          nav(`/order/${shopName}/${orderPk}/${nextPage.option_type}/`)
        }
      }
    }
  }

  let onClickSubmit = null
  if (viewType === 'choice') {
    onClickSubmit = onClickSubmitChoice
  } else {
    onClickSubmit = onClickSubmitInput
  }

  return (
    <>
      <ProgressBar
        checkMode={checkMode}
        contentWidth={contentWidth}
        orderIndex={currentPage.order}
      />
      <div style={{ width: contentWidth }}>
        <Title contentWidth={contentWidth} title={title} />
      </div>
      {viewType === 'choice' ? (
        <VerticalChoiceLayout
          argument={optionType}
          options={options}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          setButtonDisabled={setButtonDisabled}
          setEstimatePrice={setEstimatePrice}
          originPrice={originPrice}
          contentWidth={contentWidth}
          showDesc={currentPage.show_desc}
          showPrice={currentPage.show_price_in_button}
          showPhoto={currentPage.show_photo}
        />
      ) : viewType === 'input' ? (
        <InputLayout
          key={currentPage.optionType}
          optionType={optionType}
          options={options}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          setButtonDisabled={setButtonDisabled}
          setEstimatePrice={setEstimatePrice}
          placeHolder={currentPage.place_holder}
          progressOrder={currentPage.order}
          priceUnit={currentPage.pricing_unit}
          isMandatory={currentPage.mandatory}
          content={content}
          setContent={setContent}
        />
      ) : viewType === 'custom' ? (
        <CustomInput />
      ) : (
        <></>
      )}
      {viewType === 'custom' ? null : checkMode ||
        !currentPage.show_price_in_button ? (
        <NextButtonWithoutPrice
          buttonDisabled={buttonDisabled}
          onClickSubmit={onClickSubmit}
          contentWidth={contentWidth}
          checkMode={checkMode}
        />
      ) : (
        <NextButtonWithPrice
          buttonDisabled={buttonDisabled}
          onClickSubmit={onClickSubmit}
          contentWidth={contentWidth}
          checkMode={checkMode}
          estimatePrice={estimatePrice}
        />
      )}
    </>
  )
}
