import DesignCard from "./DesignCard";
import { memo } from "react";
import { Product } from "../type";
const DesignGrid = ({ contentWidth, products, productGroupName, checkIsSelected }: any) => {
    return (<div className='design-grid'
        style={{ width: contentWidth - 50 }}>
        {products?.map((product: Product) => (
            <DesignCard
                key={productGroupName + product.name}
                checkIsSelected={checkIsSelected}
                product={product}
            />))}
    </div >)
}
export default memo(DesignGrid)
