import { useEffect, useState } from 'react'
import Intro from './OrderSequence/Intro'
import { getOrderSheet, getShopByName } from '../api'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

export default function Order() {
  const { shopName, orderPk } = useParams()
  const shopQuery = useQuery(['shopByName', shopName], getShopByName)
  const [shopPk, setShopPk] = useState(-1)
  useEffect(() => {
    if (shopQuery.data) {
      setShopPk(shopQuery.data.pk)
    }
  }, [shopQuery])

  const orderSheetQuery = useQuery(
    ['orderSheet', shopName, orderPk],
    getOrderSheet,
  )

  let storage = sessionStorage

  useEffect(() => {
    storage.clear()
  }, [])

  const [color1, setColor1] = useState('')
  useEffect(() => {
    if (orderSheetQuery.data && !orderSheetQuery.isLoading) {
      storage.setItem('pages', JSON.stringify(orderSheetQuery.data.pages))
      storage.setItem(
        'calendarNoti',
        JSON.stringify(orderSheetQuery.data.calendar_noti),
      )
      storage.setItem(
        'endDateBuffer',
        JSON.stringify(orderSheetQuery.data.end_date_buffer),
      )
      storage.setItem(
        'startDateBuffer',
        JSON.stringify(orderSheetQuery.data.start_date_buffer),
      )
      storage.setItem(
        'quickAllowed',
        JSON.stringify(orderSheetQuery.data.quick_allowed),
      )
      storage.setItem(
        'defaultMonth',
        JSON.stringify(orderSheetQuery.data.default_month),
      )
      storage.setItem('shopData', JSON.stringify(shopQuery.data))
      storage.setItem(
        'startDate',
        JSON.stringify(orderSheetQuery.data.start_date),
      )
      storage.setItem('endDate', JSON.stringify(orderSheetQuery.data.end_date))
      storage.setItem('outLink', orderSheetQuery.data.out_link)
      storage.setItem('orderType', orderSheetQuery.data.order_type)
      storage.setItem('startPoint', orderSheetQuery.data.start_point)
      storage.setItem('color1', orderSheetQuery.data.color_1)
      storage.setItem('color2', orderSheetQuery.data.color_2)
      storage.setItem('color3', orderSheetQuery.data.color_3)
      storage.setItem('options', JSON.stringify(orderSheetQuery.data.options))
      storage.setItem(
        'blockedDates',
        JSON.stringify(orderSheetQuery.data.blocked_dates),
      )
      storage.setItem(
        'blockedDay',
        JSON.stringify(orderSheetQuery.data.blocked_day),
      )
      storage.setItem(
        'blockedTimes',
        JSON.stringify(orderSheetQuery.data.blocked_times),
      )
      storage.setItem('startTime', orderSheetQuery.data.start_time)
      storage.setItem('endTime', orderSheetQuery.data.end_time)
      setColor1(orderSheetQuery.data.color_1)
      storage.setItem('products', JSON.stringify(orderSheetQuery.data.products))
    }
  }, [orderSheetQuery])
  const [isContentWidthSet, setIsContentWidthSet] = useState(false)
  useEffect(() => {
    if (!isContentWidthSet) {
      if (window.innerWidth < 600) {
        // 처음에 한번만 width를 세팅하도록 한다.
        setIsContentWidthSet(true)
        storage.setItem('contentWidth', window.innerWidth.toString())
      } else {
        setIsContentWidthSet(true)
        storage.setItem('contentWidth', '400')
      }
    }
  }, [])

  return (
    <>
      <Intro
        shopData={shopQuery.data}
        orderData={orderSheetQuery.data}
        color1={color1}
      />
    </>
  )
}
