import { Option, Product } from '../OrderSequence/type'
import {
  CalculatePrice,
  FilterBySelectedOption,
  GetFilterFromOption,
} from './utils'
function isJson(str: string) {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}
const changeOptionByOtherOption = (
  optionToChange: Option,
  filterOption: Option,
) => {
  //args: optionToChange, filterOption
  // filterOption의 filter로 지정된 option들 중에서 optionToChange와 이름이 동일한 option을 찾아서 내보낸다.
  const allOptions = JSON.parse(sessionStorage.getItem('options') as string)
  let filter = GetFilterFromOption(filterOption)
  if (!filter) {
    return optionToChange
  }
  if (filterOption && allOptions) {
    const optionFiltered = FilterBySelectedOption(
      allOptions,
      optionToChange.option_type,
      [filterOption],
    )
    if (optionFiltered) {
      const option = optionFiltered.find(
        (option: Option) => option.name === optionToChange.name,
      )
      sessionStorage.setItem(optionToChange.option_type, JSON.stringify(option))
      return option
    } else {
      return null
    }
  }
  throw new Error('design Option에 의해 변경되지 못했습니다')
}
const checkCompatible = (design: Option, otherOption: Option) => {
  let filter = GetFilterFromOption(design)
  if (!filter) {
    return otherOption
  }
  if (otherOption.option_type in design.information.filter) {
    if (
      design.information.filter[otherOption.option_type].includes(
        otherOption.id,
      )
    ) {
      return otherOption
    } else {
      return null
    }
  }
  return otherOption
}
export class OrderCheckManager {
  pages: any[]
  maxOrder: number
  optionStringType: { [key: string]: string }
  optionJsonType: { [key: string]: Option }
  selectedOrders: number[]
  product: Product
  constructor(pages: any[]) {
    this.pages = pages
    pages.sort((a, b) => a.order - b.order)
    this.selectedOrders = []
    this.maxOrder = pages[pages.length - 1].order
    let optionStringType: any = {}
    let optionJsonType: { [key: string]: Option } = {}
    this.product = JSON.parse(sessionStorage.getItem('product') as string)
    pages.forEach((page: any) => {
      let optionString = sessionStorage.getItem(page.option_type) as string
      if (optionString) {
        this.selectedOrders.push(page.order)
        if (isJson(optionString)) {
          let option = JSON.parse(optionString as string)
          optionJsonType[page.option_type] = option
        } else {
          optionStringType[page.option_type] = optionString
        }
      }
    })
    this.optionJsonType = optionJsonType
    this.optionStringType = optionStringType
    // this.checkOptionsCompatible()
  }
  checkOptionsCompatible() {
    // TODO 사이즈로 인해 base가 필터링 되는 경우가 있는데, 그 경우는 현재 커버하지 못함
    let productOption = this.optionJsonType.product
    for (const [arg, optionJson] of Object.entries(this.optionJsonType)) {
      if (arg !== 'product') {
        let newOption = changeOptionByOtherOption(optionJson, productOption)
        this.optionJsonType[arg] = newOption as Option
        sessionStorage.setItem(arg, JSON.stringify(newOption))
      }
    }
  }
  checkIsSomethingVacant() {
    // 모든 옵션들이 다 확보되었는지 체크
    for (let index = 0; index <= this.maxOrder; index++) {
      if (!this.selectedOrders.includes(index)) {
        let vacantPage = this.pages.find((page: any) => page.order === index)
        if (vacantPage.option_type === 'user') {
          continue
        }
        console.log(index)
        return true
      }
    }

    return false
  }
  getDsiplayList() {
    let res = []
    // 또는 Object.entries를 사용하여 키-값 쌍을 가져와 순회
    for (const [arg, optionJson] of Object.entries(this.optionJsonType)) {
      if (arg === 'product' || arg === 'custom_boolean') {
        // TODO custom boolean은 page의 check disabled 값으로 변경
        continue
      }
      let optionPage = this.pages.find((page: any) => page.option_type === arg)
      const storageKey = arg
      const title = optionPage.title
      let optionType = storageKey.charAt(0).toUpperCase() + storageKey.slice(1)
      let message = ''
      let price = 0
      let isVacant = false
      if (optionJson) {
        if (optionPage.view_type === 'input') {
          let content = sessionStorage.getItem(`${arg}Content`)
          message = content ? content : optionJson.name
          price = optionJson.value
        } else {
          message = optionJson.name
          price = optionJson.value
        }
      } else {
        message = title
        isVacant = true
      }
      res.push({ title, message, optionType, price, isVacant })
    }

    return res
  }
  getOption(arg: string) {
    return this.optionJsonType[arg]
  }
  getProduct() {
    return this.product
  }
  getTotalPrice() {
    return CalculatePrice('')
  }
}
