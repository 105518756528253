import { useNavigate, useParams } from 'react-router-dom'
import '../../../css/DesignDetail.scss'
import { CalculateDesignMinPrice } from '../../../components/utility/utils'
import { colorCharcoal } from '../../../components/OrderSequence/Constants'
import { Product } from '../../../components/OrderSequence/type'
export default function ProductDetail() {
  const { shopName, orderPk, productPk } = useParams()
  const storage = sessionStorage
  let productData = JSON.parse(storage.getItem('products') as string)

  let productSelected = productData.find(
    (product: Product) => product.id === Number(productPk),
  )
  let minPrice = CalculateDesignMinPrice(productSelected)

  const contentWidth = Number(storage.getItem('contentWidth'))
  const nav = useNavigate()
  const pages = JSON.parse(storage.getItem('pages') as string)
  const productPage = pages.find((page: any) => page.option_type === 'product')

  const onClickBack = () => {
    nav(`/order/${shopName}/${orderPk}/product/${productPage.view_type}`, {
      state: { prevPath: 'productDetail' },
    })
  }
  const checkMode = storage.getItem('checkMode')

  const onClickButton = () => {
    if (productSelected) {
      storage.setItem('product', JSON.stringify(productSelected))
      storage.setItem(
        'images',
        JSON.stringify([productSelected.example_photos[0].src]),
      )
      let productSelectedNextPoint = productSelected.next_point
      if (checkMode) {
        if (productSelectedNextPoint === 'number') {
          storage.removeItem('size')
        }
        if (productSelectedNextPoint === 'size') {
          storage.removeItem('number')
        }
      }

      const nextPage = pages.find(
        (page: any) =>
          page.order === productPage.order + 1 &&
          page.option_type === productSelectedNextPoint,
      )
      nav(
        `/order/${shopName}/${orderPk}/${productSelectedNextPoint}/${nextPage.view_type}`,
      )
    }
  }
  const DivideDesc = (str: string) => {
    if (str && str.includes('_')) {
      const strList = str.split('_')
      return (
        <div>
          {strList[0]}
          <br />
          {strList[1]}
        </div>
      )
    }
    return str
  }
  return (
    <div
      onLoad={() => {
        window.scrollTo({ top: 0 })
      }}
      style={{
        width: contentWidth,
      }}
    >
      <div
        className="top-gradient"
        style={{
          position: 'fixed',
          width: contentWidth,
          height: '70px',
          zIndex: 2,
        }}
      ></div>
      <div
        onClick={onClickBack}
        style={{
          zIndex: 3,
          position: 'fixed',
          top: '20px',
          left: '20px',
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
        >
          <path
            d="M11 1L2 10.5L11 20"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
      </div>
      <img
        style={{
          height: contentWidth,
          width: contentWidth,
          objectFit: 'cover',
        }}
        src={productSelected.example_photos[0].src}
        alt=""
      />
      <div
        style={{
          paddingLeft: '20px',
          paddingRight: '20px',
          paddingTop: '24px',
          paddingBottom: '60px',
        }}
      >
        {productSelected ? (
          <div>
            <div className="design-detail-title">
              <div>{productSelected.name}</div>
              {'label' in productSelected.information ? (
                <div>*{productSelected.information.label}</div>
              ) : null}
            </div>
            <div className="design-detail-desc">
              <div style={{ color: colorCharcoal }}>
                {minPrice.toLocaleString('en-US')}원 부터
              </div>
              {DivideDesc(productSelected.desc)}
            </div>
          </div>
        ) : null}
      </div>
      <div
        style={{
          width: contentWidth,
          display: 'flex',
          paddingLeft: 20,
          paddingRight: 20,
          position: 'fixed',
          bottom: 0,
        }}
      >
        <button
          style={{
            width: '100%',
            background: '#eaeaea',
            marginBottom: 25,
            marginRight: 9,
          }}
          className="close-button"
          onClick={onClickBack}
        >
          닫기
        </button>
        <button
          onClick={onClickButton}
          className="close-button"
          style={{
            color: 'white',
            width: '100%',
            background: colorCharcoal,
            marginBottom: 25,
          }}
        >
          <span>{'케이크 선택하기'}</span>
        </button>
      </div>
    </div>
  )
}
