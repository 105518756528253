import { Grid } from '@chakra-ui/react'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import ProgressBar from '../../components/OrderSequence/PageElement/ProgressBar'
import { NextButtonWithoutPrice } from '../../components/OrderSequence/PageElement/NextButton'
import { Title } from '../../components/OrderSequence/Format'
import {
  getCurrentPage,
  getNextPage,
  parseTime,
} from '../../components/utility/utils'
import {
  colorCharcoal,
  colorDarkGray,
  colorGrayOpacity1st,
  colorGrayOpacity2nd,
  colorGrayOpacity3rd,
  colorGrayOpacity4th,
  globalMargin,
} from '../../components/OrderSequence/Constants'
import QuickInput from '../../components/OrderSequence/PageElement/QuickInput'
import '../../css/SelectTime.scss'

const TimeGrid = ({
  contentWidth,
  timeList,
  blockedTimesFormat,
  timeSelected,
  onClickTime,
}: any) => {
  return (
    <Grid
      marginLeft={`${globalMargin}px`}
      width={contentWidth - globalMargin * 2}
      columnGap={'6px'}
      rowGap={'6px'}
      templateColumns={'repeat(4,1fr)'}
    >
      {timeList
        ? timeList.map((timeOption: any) => (
            <TimeButton
              key={timeOption.format('HH:mm')}
              timeOption={timeOption}
              blockedTimesFormat={blockedTimesFormat}
              timeSelected={timeSelected}
              onClickTime={onClickTime}
            />
          ))
        : null}
    </Grid>
  )
}

const TimeButton = ({
  timeOption,
  blockedTimesFormat,
  timeSelected,
  onClickTime,
}: any) => {
  const blockedTimeStyle = {
    border: `1px solid ${colorGrayOpacity3rd}`,
    background: colorGrayOpacity4th,
    color: '#B8B8B8',
  }
  const openTimeStyle = {
    border: `1px solid ${colorGrayOpacity1st}`,
    background: colorGrayOpacity2nd,
  }
  const selectedTimeStyle = {
    background: colorDarkGray,
  }
  return (
    <button
      key={timeOption}
      disabled={blockedTimesFormat.includes(timeOption.format('HH:mm'))}
      onClick={() => onClickTime(timeOption)}
      style={
        blockedTimesFormat.includes(timeOption.format('HH:mm'))
          ? blockedTimeStyle
          : timeOption.format('HH:mm') === timeSelected.format('HH:mm')
          ? selectedTimeStyle
          : openTimeStyle
      }
      className={
        timeOption.format('HH:mm') === timeSelected.format('HH:mm')
          ? 'time_button_active'
          : 'time_button'
      }
    >
      {timeOption.format('HH:mm')}
    </button>
  )
}

export default function SelectTime() {
  const dayjs = require('dayjs')
  const timezone = require('dayjs/plugin/timezone')
  const utc = require('dayjs/plugin/utc')
  dayjs.extend(utc)
  dayjs.extend(timezone)
  dayjs.tz.setDefault('Asia/Seoul')
  let storage = sessionStorage
  const pages = JSON.parse(storage.getItem('pages') as string)
  const currentPage = getCurrentPage('time')
  const nextPage = getNextPage(currentPage)
  const productPage = pages.find((page: any) => page.option_type === 'product')

  const date = dayjs(storage.getItem('date') as string)
  const contentWidth = Number(storage.getItem('contentWidth'))
  const checkMode = storage.getItem('checkMode')
  const startTime = parseTime(storage.getItem('startTime') as string)
  const endTime = parseTime(storage.getItem('endTime') as string)

  let blocked_times = JSON.parse(storage.getItem('blockedTimes') as string)

  const [isSetFinished, SetIsSetFinished] = useState(false)
  useEffect(() => {
    if (blocked_times && !isSetFinished) {
      let draftBlockTimes: any = []
      let draftBlockTimesFormat: any = []
      blocked_times.forEach((time: any) => {
        var timeParsed = dayjs.tz(time.blocked_time)
        const dateTmp = timeParsed.get('date')
        const monthTmp = timeParsed.get('month')
        const yearTmp = timeParsed.get('year')
        if (
          date.get('date') === dateTmp &&
          date.get('month') === monthTmp &&
          date.get('year') === yearTmp
        ) {
          draftBlockTimes.push(timeParsed)
          draftBlockTimesFormat.push(timeParsed.format('HH:mm'))
        }
      })
      setBlockedTimesFormat(draftBlockTimesFormat)
      SetIsSetFinished(true)
    }
  }, [blocked_times])
  const [blockedTimesFormat, setBlockedTimesFormat] = useState<string[]>([])
  let midTime = startTime
  let timeList1 = []
  let timeList2 = []
  while (midTime <= endTime) {
    if (midTime.hour() < 12) {
      timeList1.push(midTime)
    } else {
      timeList2.push(midTime)
    }
    midTime = midTime.add(30, 'minute')
  }
  const nav = useNavigate()
  const { shopName, orderPk } = useParams()

  const initTime = dayjs().set('hour', 1).set('minute', 44)
  const [timeSelected, setTimeSelected] = useState(initTime)
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const onClickTime = (timeOption: any) => {
    setTimeSelected(timeOption)
  }

  const [quickTime, setQuickTime] = useState('')
  useEffect(() => {
    let timeOrigin = storage.getItem('time') as string
    let quickTimeOrigin = storage.getItem('quickTime') as string
    let quickSelected = Boolean(storage.getItem('quickRequired'))
    if (quickSelected) {
      setQuickRequired(quickSelected)
      setQuickTime(quickTimeOrigin)
      setTimeSelected(dayjs(initTime))
    } else {
      if (timeOrigin) {
        setTimeSelected(dayjs(timeOrigin))
      }
    }
  }, [])

  const isQuickAllowed = JSON.parse(storage.getItem('quickAllowed') as string)
  const [quickRequired, setQuickRequired] = useState(false)
  const onClickQuickService = () => {
    setQuickRequired(!quickRequired)
  }
  const ref = useRef<HTMLDivElement>(null)
  const [coverHeight, setCoverHeight] = useState(0)
  useEffect(() => {
    if (quickRequired) {
      if (ref.current) {
        setCoverHeight(ref.current.clientHeight)
        setTimeSelected(initTime)
      }
    }
  }, [quickRequired])
  const onChangeQuickTime = (event: any) => {
    setQuickTime(event.target.value)
  }

  // 버튼 활성화 기준
  useEffect(() => {
    if (!quickRequired) {
      if (timeSelected === initTime) {
        setButtonDisabled(true)
      } else {
        setButtonDisabled(false)
      }
    } else {
      if (quickRequired && quickTime.length !== 0) {
        setButtonDisabled(false)
      } else {
        setButtonDisabled(true)
      }
    }
  }, [quickRequired, quickTime, timeSelected])

  const onClickSubmitTime = () => {
    if (!quickRequired) {
      storage.setItem('time', timeSelected?.toString())
    } else if (quickRequired) {
      storage.setItem('quickTime', quickTime)
      storage.setItem('quickRequired', quickRequired?.toString())
    }
    let custom = true
    if (checkMode) {
      nav(`/order/${shopName}/${orderPk}/Check`)
    } else if (custom) {
      nav(
        `/order/${shopName}/${orderPk}/${nextPage.option_type}/${nextPage.view_type}`,
      )
    } else {
      nav(`/order/${shopName}/${orderPk}/design/${productPage.view_type}`)
    }
  }
  return (
    <div className="select-time">
      <ProgressBar
        checkMode={checkMode}
        contentWidth={contentWidth}
        orderIndex={2}
      />

      <div style={{ width: contentWidth }}>
        <Title title={currentPage.title} />
        <div ref={ref}>
          {quickRequired ? (
            <div
              style={{
                position: 'absolute',
                width: contentWidth,
                height: coverHeight,
                background: 'white',
                opacity: 0.8,
                zIndex: 3,
              }}
            >
              {' '}
            </div>
          ) : (
            <></>
          )}
          {timeList1.length > 0 && (
            <>
              <p>오전</p>
              <TimeGrid
                contentWidth={contentWidth}
                timeList={timeList1}
                blockedTimesFormat={blockedTimesFormat}
                timeSelected={timeSelected}
                onClickTime={onClickTime}
              />
            </>
          )}

          {timeList2.length > 0 && (
            <>
              <p>오후</p>
              <TimeGrid
                contentWidth={contentWidth}
                timeList={timeList2}
                blockedTimesFormat={blockedTimesFormat}
                timeSelected={timeSelected}
                onClickTime={onClickTime}
              />
            </>
          )}

          <div className="legend">
            <div
              style={{
                border: `1px solid ${colorGrayOpacity1st}`,
                background: colorGrayOpacity2nd,
              }}
            ></div>
            <div>픽업가능</div>
            <div
              style={{
                background: colorGrayOpacity4th,
                border: `1px solid ${colorGrayOpacity3rd}`,
              }}
            ></div>
            <div>픽업불가</div>
          </div>
        </div>

        {isQuickAllowed ? (
          <QuickInput
            contentWidth={contentWidth}
            onClickQuickService={onClickQuickService}
            quickRequired={quickRequired}
            quickTime={quickTime}
            onChangeQuickTime={onChangeQuickTime}
          />
        ) : null}
        <div style={{ height: 140, width: '100%' }}></div>
        <NextButtonWithoutPrice
          buttonDisabled={buttonDisabled}
          onClickSubmit={onClickSubmitTime}
          contentWidth={contentWidth}
          color1={colorCharcoal}
          checkMode={checkMode}
        />
      </div>
    </div>
  )
}
