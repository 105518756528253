import '../../css/Common.scss'
import { useState } from 'react'
import { CheckSelected } from '../../components/utility/utils'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
import { colorDarkGray } from './Constants'
import { LineBreakedString } from '../utility/LineBreakedString'

export function LetterInput({
  contentWidth,
  letter,
  onChangeText,
  textCount,
}: any) {
  return (
    <div
      style={{
        width: contentWidth - 50,
        textAlign: 'right',
        marginBottom: 150,
      }}
    >
      <textarea
        style={{
          width: contentWidth - 50,
          textAlign: 'left',
        }}
        value={letter}
        onChange={onChangeText}
        placeholder="ex) 엄마아빠 환갑 축하드려요!"
        className="lettering_input"
      />
      <span className="letter-count-main">{textCount}자</span>
      <span className="letter-count-sub">/ 20자</span>
    </div>
  )
}
export function VerticalChoiceButton({
  contentWidth,
  option,
  selectedOption,
  onClickButton,
  showDesc,
  showPrice,
  showPhoto,
}: any) {
  const dropDownActive = showDesc || showPhoto
  return (
    <div
      key={option.name}
      style={
        CheckSelected(option, selectedOption)
          ? {
              marginTop: 10,
              width: contentWidth - 50,
              left: 25,
              border: `1px solid ${colorDarkGray}`,
              background: '#FFFFFF',
            }
          : {
              marginTop: 10,
              width: contentWidth - 50,
              left: 25,
              background: '#F3F3F3',
            }
      }
      className={
        CheckSelected(option, selectedOption)
          ? 'size_button_active'
          : 'size_button'
      }
      onClick={() => onClickButton(option)}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <p
            className={
              CheckSelected(option, selectedOption)
                ? 'size_option_text_active'
                : 'size_option_text'
            }
          >
            {option.name}{' '}
            {showPrice ? (
              <>
                <span style={{ color: '#000000', opacity: 0.2 }}>•</span>{' '}
                {option.value.toLocaleString('en-US')}원
              </>
            ) : null}
          </p>
          {typeof option.information === 'object' &&
          'label' in option.information ? (
            CheckSelected(option, selectedOption) ? (
              <div
                style={{ background: colorDarkGray }}
                className="best-mark-active"
              >
                {option.information.label}
              </div>
            ) : (
              <div className="best-mark-inactive">
                {option.information.label}
              </div>
            )
          ) : null}
        </div>

        {dropDownActive ? (
          option === selectedOption ? (
            <button>
              <IoIosArrowUp />
            </button>
          ) : (
            <button>
              <IoIosArrowDown />
            </button>
          )
        ) : null}
      </div>

      {dropDownActive && CheckSelected(option, selectedOption) ? (
        <>
          <p className="size_option_text_desc">{option.desc}</p>
        </>
      ) : null}
    </div>
  )
}

export function Title({ title, customMarginTop }: any) {
  return (
    <div
      className="title-group"
      style={
        customMarginTop
          ? {
              marginTop: customMarginTop,
            }
          : {}
      }
    >
      {LineBreakedString(title)}
    </div>
  )
}
