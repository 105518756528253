
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

export function MenuGroupCarousel({
    productGrouped,
    checkGroupSelected,
    onClickCarouselButton,
    contentWidth,
}: any) {

    const settings = {
        className: 'slider variable-width',
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
    }

    return (
        <div
            className="menu-group-carousel"
            style={{
                width: contentWidth,
                height: 55
            }}
        >
            <Slider
                style={{ width: contentWidth - 50, height: '45px' }}
                {...settings}
            >
                {productGrouped ? Object.keys(productGrouped).map((productGroupName: any) => (
                    <div key={productGroupName}>
                        <div
                            className={
                                checkGroupSelected(productGroupName)
                                    ? 'design-button-active'
                                    : 'design-button-inactive'
                            }
                            onClick={() => onClickCarouselButton(productGroupName)}
                        >
                            {productGroupName}
                        </div>
                    </div>
                )) : null}
            </Slider>
        </div>
    )
}
