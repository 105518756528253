import { useState, useEffect } from 'react'
import '../../../css/Common.scss'
import '../../../css/SelectPhoto.scss'
import '../../../css/Format.scss'

import { useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { colorCharcoal } from '../../../components/OrderSequence/Constants'
import { NextButtonWithoutPrice } from './NextButton'
import { getNextPage } from '../../utility/utils'
const AddPhoto = ({
  customSelected,
  images,
  setImages,
  text,
  setText,
}: any) => {
  const [textCount, setTextCount] = useState(0)
  const onChangeText = (e: any) => {
    if (e.target.value.length > 200) {
      return
    }
    setTextCount(e.target.value.length)
    setText(e.target.value)
  }

  const [active, setActive] = useState(true)
  useEffect(() => {
    if (customSelected) {
      setActive(true)
    } else {
      setActive(false)
    }
  }, [customSelected])

  const onChangePhotoInput = (e: any) => {
    const targetFiles = (e.target as HTMLInputElement).files as FileList
    const targetFilesArray = Array.from(targetFiles)
    if (targetFilesArray.length > 3) {
      let length = targetFilesArray.length
      targetFilesArray.splice(3, length - 3)
      const selectedFiles: string[] = targetFilesArray.map(file => {
        return URL.createObjectURL(file)
      })
      setImages((prev: any) => prev.concat(selectedFiles))
    } else {
      const selectedFiles: string[] = targetFilesArray.map(file => {
        return URL.createObjectURL(file)
      })
      setImages((prev: any) => prev.concat(selectedFiles))
    }
  }
  const fileRef = useRef<HTMLInputElement>(null)
  const handleClick = () => {
    fileRef?.current?.click()
  }
  const onClickDelete = (url: string) => {
    const newImages = [...images]
    const index = newImages.indexOf(url, 0)
    if (index > -1) {
      newImages.splice(index, 1)
    }
    setImages(newImages)
  }
  return (
    <>
      <div
        style={{
          borderLeft: `1.5px solid ${colorCharcoal}`,
          borderRight: `1.5px solid ${colorCharcoal}`,
          borderTop: `1.5px solid ${colorCharcoal}`,
        }}
        className="add-photo-header"
      >
        <div>
          <p className="button-text">사진/도안 첨부하기 </p>
        </div>
      </div>
      <div
        style={{
          borderLeft: `1.5px solid ${colorCharcoal}`,
          borderRight: `1.5px solid ${colorCharcoal}`,
          borderBottom: `1.5px solid ${colorCharcoal}`,
        }}
        className="add-photo-body"
      >
        <div className="title-in-box">사진 (최대3장)</div>
        <input
          ref={fileRef}
          style={{ display: 'none' }}
          onChange={onChangePhotoInput}
          type="file"
          multiple
          accept="image/jpeg,image/jpg,image/png"
        />
        <div className="photo-input">
          <button disabled={images.length >= 3} onClick={handleClick}>
            <img src="/camera.png" />
            <p>{images ? images.length : 0}/3</p>
          </button>
          {images.map((url: string, i: number) => (
            <div className="preview-image-block">
              <img className="preview-image" key={url} src={url} />
              <button
                onClick={() => {
                  onClickDelete(url)
                }}
              >
                <img className="delete-button" src="/delete_photo.png" />
              </button>
            </div>
          ))}
        </div>
        <div className="custom-message">{`요청사항 (${textCount}/200)`}</div>
        <textarea
          onChange={onChangeText}
          value={text}
          placeholder="구체적으로 생각하시는 디자인이 있으시면 설명을 적어주세요"
        />
        <div>
          {' '}
          • 자유디자인의 경우 희망하시는 내용에 따라 진행이 불가능 하실 수 도
          있습니다
        </div>
      </div>
    </>
  )
}

export default function CustomInput() {
  const [customSelected, setCustomSelected] = useState(false)
  let storage = sessionStorage
  const pages = JSON.parse(storage.getItem('pages') as string)

  const curPage = pages.find((page: any) => page.option_type === 'custom')
  const nextPage = getNextPage(curPage)

  let customImages = storage.getItem('images')

  const [images, setImages] = useState<string[]>([])

  let customMessage = storage.getItem('customMessage')
  let draftMessage = ''
  if (customMessage) {
    draftMessage = customMessage
  }
  const [text, setText] = useState<string>(draftMessage)
  const checkMode = storage.getItem('checkMode')

  const contentWidth = Number(storage.getItem('contentWidth'))
  const [buttonDisabled, setButtonDisabled] = useState(true)

  useEffect(() => {
    let draft_Images = []
    if (customImages) {
      draft_Images = JSON.parse(customImages)
      setImages(draft_Images)
      setCustomSelected(true)
    }
  }, [])

  const nav = useNavigate()
  const { shopName, orderPk } = useParams()
  const onClickSubmitCustom = () => {
    const designFreeOption = {
      id: 1,
      name: '자유형',
      option_type: 'product',
      value: 0,
    }
    storage.setItem('product', JSON.stringify(designFreeOption))
    storage.setItem('images', JSON.stringify(images))
    storage.setItem('customMessage', text)

    if (checkMode) {
      nav(`/order/${shopName}/${orderPk}/Check`)
    } else {
      nav(
        `/order/${shopName}/${orderPk}/${nextPage.option_type}/${nextPage.view_type}`,
      )
    }
  }

  useEffect(() => {
    if (images && customSelected) {
      if (customSelected && images.length != 0) {
        setButtonDisabled(false)
      } else if (customSelected && images.length === 0) {
        setButtonDisabled(true)
      } else if (!customSelected) {
        setButtonDisabled(false)
      }
    }
    if (!customSelected) {
      setButtonDisabled(false)
    }
  }, [images, customSelected])
  return (
    <div style={{ width: contentWidth }}>
      <div className="photo-select-box">
        <AddPhoto
          customSelected={customSelected}
          images={images}
          setImages={setImages}
          text={text}
          setText={setText}
          contentWidth={contentWidth}
        />
      </div>
      <NextButtonWithoutPrice
        buttonDisabled={buttonDisabled}
        onClickSubmit={onClickSubmitCustom}
        contentWidth={contentWidth}
        checkMode={checkMode}
      />
    </div>
  )
}
