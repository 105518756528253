import '../../../css/Common.scss'
import { VerticalChoiceButton } from '../Format'

export default function VerticalChoiceLayout({
  options,
  selectedOption,
  setSelectedOption,
  setButtonDisabled,
  setEstimatePrice,
  originPrice,
  contentWidth,
  showDesc,
  showPrice,
  showPhoto,
}: any) {
  const onClickButton = (option: any) => {
    if (selectedOption && option.name === selectedOption.name) {
      let x = { ...option }
      x.name = ''
      setSelectedOption(x)
      setButtonDisabled(true)
    } else {
      setSelectedOption(option)
      setButtonDisabled(false)
      setEstimatePrice(originPrice + option.value)
    }
  }
  return (
    <>
      <div style={{ marginLeft: 25, marginTop: 35, marginBottom: 100 }}>
        <div style={{ width: contentWidth }}>
          {options.length !== 0
            ? options.map((option: any) => (
                <VerticalChoiceButton
                  key={`${option.name}${option.value}`}
                  contentWidth={contentWidth}
                  option={option}
                  selectedOption={selectedOption}
                  onClickButton={onClickButton}
                  showDesc={showDesc}
                  showPrice={showPrice}
                  showPhoto={showPhoto}
                />
              ))
            : null}
        </div>
      </div>
    </>
  )
}
