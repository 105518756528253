import { useState, useEffect } from 'react'
import '../../../css/SelectProduct.scss'
import '../../../css/Common.scss'
import { Product } from '../../../components/OrderSequence/type'
import ProgressBar from '../../../components/OrderSequence/PageElement/ProgressBar'
import { Title } from '../../../components/OrderSequence/Format'
import ProductGroup from '../../../components/OrderSequence/ProductSelect/ProductGroup'
import ProductDesc from '../../../components/OrderSequence/ProductSelect/ProductDesc'
import ProductTile from '../../../components/OrderSequence/ProductSelect/ProductTile'
import ProductList from '../../../components/OrderSequence/ProductSelect/ProductList'
import { useParams } from 'react-router-dom'

export default function SelectProduct() {
  let storage = sessionStorage
  let productsData = JSON.parse(storage.getItem('products') as string)
  const contentWidth = Number(storage.getItem('contentWidth'))
  const checkMode = storage.getItem('checkMode')
  const pages = JSON.parse(storage.getItem('pages') as string)
  const productPage = pages.find((page: any) => page.option_type === 'product')

  const [isSetFinished, SetIsSetFinished] = useState(false)

  const [products, setProducts] = useState<Product[]>([])
  const [productSelected, setProductSelected] = useState<Product>()
  const { viewType } = useParams()
  useEffect(() => {
    if (productsData && !isSetFinished) {
      setProducts(productsData)

      let productTmp = JSON.parse(storage.getItem('product') as string)
      if (productTmp) {
        setProductSelected(productTmp)
      } else {
        const optionNotSoldOut = productsData.find((product: Product) => {
          if (product.sold_out) {
            return false
          } else {
            return true
          }
        })
        if (optionNotSoldOut) {
          setProductSelected(optionNotSoldOut)
        }
      }
      SetIsSetFinished(true)
    }
  }, [productsData])

  const onLoadMain = () => {
    let lastPosition = Number(sessionStorage.getItem('lastPosition'))
    if (lastPosition) {
      window.scrollTo({
        top: lastPosition,
        behavior: 'instant' as ScrollBehavior,
      })
      window.history.replaceState({}, document.title)
    }
  }

  return (
    <>
      <ProgressBar
        checkMode={checkMode}
        contentWidth={contentWidth}
        orderIndex={productPage.order}
      />
      <div
        style={{
          width: contentWidth,
          textAlign: 'center' as 'center',
        }}
      >
        <Title contentWidth={contentWidth} title={productPage.title} />

        {viewType === 'group' ? (
          <ProductGroup
            contentWidth={contentWidth}
            products={products}
            productSelected={productSelected as Product}
          />
        ) : viewType === 'tile' ? (
          <ProductTile
            contentWidth={contentWidth}
            products={products}
            productSelected={productSelected as Product}
          />
        ) : viewType === 'desc' ? (
          <ProductDesc
            contentWidth={contentWidth}
            onLoadMain={onLoadMain}
            products={products}
          />
        ) : viewType === 'list' ? (
          <ProductList
            contentWidth={contentWidth}
            onLoadMain={onLoadMain}
            products={products}
          />
        ) : null}
      </div>
    </>
  )
}
