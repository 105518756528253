import '../../../css/ShopAdmin/SettingPages/SoldOutSettings.scss'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AdminModalLayout, ModalLayout } from '../../../components/FormatModal'
import { useMutation } from '@tanstack/react-query'
import { patchProducts } from '../../../api'
import { Option } from '../../../components/OrderSequence/type'

export default function SoldOutSettings() {
  let storage = sessionStorage
  const nav = useNavigate()

  let contentWidth = Number(storage.getItem('contentWidth')) - 40
  const productsString = storage.getItem('products')

  const products = productsString ? JSON.parse(productsString) : {}
  const shownProducts = products.sort(function (a: any, b: any) {
    if (a.order > b.order) {
      return 1
    } else if (a.order === b.order) {
      return 0
    } else {
      return -1
    }
  })

  const initialCheckboxStates: { [key: number]: boolean } = {}
  shownProducts.forEach((product: any) => {
    initialCheckboxStates[product.id] = product.sold_out === true
  })

  const [hasCheckBoxChanged, setHasCheckBoxChanged] = useState(false)
  const [checkboxStates, setCheckboxStates] = useState<{
    [key: number]: boolean
  }>(initialCheckboxStates)
  const [closeModalIsOpen, setCloseModalIsOpen] = useState(false)
  const [saveModalIsOpen, setSaveModalIsOpen] = useState(false)

  const changeSoldOutProductMutation = useMutation(patchProducts, {
    onError: (data: any) => {
      alert(data)
    },
  })

  useEffect(() => {
    const anyCheckboxValueChanged = Object.keys(checkboxStates).some(
      productId => {
        const product = shownProducts.find(
          (product: { id: number }) => product.id === Number(productId),
        )
        if (product) {
          const isChecked = checkboxStates[Number(productId)]
          return isChecked !== (product.sold_out === true)
        }
        return false
      },
    )
    setHasCheckBoxChanged(anyCheckboxValueChanged)
  }, [checkboxStates, shownProducts])

  const handleCheckboxChange = (event: {
    target: { value: any; checked: any }
  }) => {
    const productId = event.target.value
    const isChecked = event.target.checked
    setCheckboxStates({
      ...checkboxStates,
      [productId]: isChecked,
    })
  }

  const onSaveClick = () => {
    if (hasCheckBoxChanged) {
      const productIds = Object.keys(checkboxStates).join(',')
      const soldOutStatus = Object.values(checkboxStates)
        .map(value => (value ? true : false))
        .join(',')

      changeSoldOutProductMutation.mutate({
        productIds: productIds,
        soldOutStatus,
      })
      setSaveModalIsOpen(true)
    }
  }
  const onClickX = () => {
    if (hasCheckBoxChanged) {
      setCloseModalIsOpen(true)
    } else {
      nav(-1)
    }
  }

  const onClickFinishForModal = () => {
    nav(-1)
  }

  return (
    <div className="sold-out-admin-container">
      <div className="title-container" style={{ width: contentWidth }}>
        <div className="title">품절상품 설정</div>
        <button onClick={onClickX}>
          <img
            style={{
              height: 15.8,
              width: 15.8,
            }}
            src="/x.png"
          />
        </button>
        <AdminModalLayout
          modalIsOpen={closeModalIsOpen}
          setModalIsOpen={setCloseModalIsOpen}
          onClickFinish={onClickFinishForModal}
        ></AdminModalLayout>
      </div>
      <div
        className="product-options-container"
        style={{ width: contentWidth }}
      >
        {shownProducts.map((productOption: any) => (
          <React.Fragment key={productOption.id}>
            <div className="product-option-individual-container">
              <div className="product-option-individual-text-container">
                <span
                  className={`${
                    checkboxStates[productOption.id]
                      ? 'product-option-selected'
                      : 'product-option-not-selected'
                  }`}
                >
                  {productOption.product_group
                    ? `${productOption.product_group} ${productOption.name}`
                    : productOption.name}
                </span>
                {checkboxStates[productOption.id] && (
                  <span className="sold-out-text"> 품절</span>
                )}
              </div>
              <input
                type="checkbox"
                checked={checkboxStates[productOption.id]}
                onChange={handleCheckboxChange}
                value={productOption.id}
                className="checkbox"
              />
            </div>
            <div className="product-option-divider"></div>
          </React.Fragment>
        ))}
      </div>
      <div className="save-button-container" style={{ width: contentWidth }}>
        <button
          onClick={onSaveClick}
          disabled={!hasCheckBoxChanged}
          className="save-button"
          style={{
            width: contentWidth,
            background: hasCheckBoxChanged ? '#0baa5d' : '#B8B8B8',
          }}
        >
          저장
        </button>
      </div>
      <ModalLayout
        title="주문서 설정 변경 완료"
        subTitle="고객에게도 변경된 내용으로 보여집니다"
        modalIsOpen={saveModalIsOpen}
        onClickFinish={onClickFinishForModal}
      ></ModalLayout>
    </div>
  )
}
