import '../../../css/ShopAdmin/MainPage/SoldOutSettingNavButton.scss'
import React from 'react'
import { CaretRight } from 'phosphor-react'
import { useNavigate } from 'react-router-dom'
import { Option } from '../../OrderSequence/type'

export default function SoldOutSettingNavButton({
  products,
  shopUuid,
  shopName,
}: any) {
  const nav = useNavigate()

  const isAnySoldOut = products.some(
    (product: any) => product.sold_out === true,
  )
  const handleClick = () => {
    nav(`/shopAdmin/${shopUuid}/${shopName}/P/soldOutAdmin`)
  }

  const soldOutProductOptions = products
    .filter((product: any) => product.sold_out === true)
    .sort(function (a: any, b: any) {
      if (a.order > b.order) {
        return 1
      } else if (a.order === b.order) {
        return 0
      } else {
        return -1
      }
    })

  return (
    <div className="soldOutButton" onClick={handleClick}>
      <div className="buttonText">
        {isAnySoldOut ? (
          soldOutProductOptions.map((soldOutOption: any, index: number) => (
            <span key={index}>
              {index > 0 && ', '}
              <span style={{ fontWeight: 'bold' }}>
                {soldOutOption.product_group
                  ? `${soldOutOption.product_group} ${soldOutOption.name}`
                  : soldOutOption.name}
              </span>
              {index === soldOutProductOptions.length - 1 ? ' 품절중' : ''}
            </span>
          ))
        ) : (
          <span>품절상품 설정하기</span>
        )}
      </div>
      <CaretRight className="caretRight" />
    </div>
  )
}
