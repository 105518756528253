import { useState, useEffect } from 'react'
import { Option, Product } from '../../type'
import { OptionContent } from './OptionContent'
const DesingFreeNoti = () => {
  return (
    <p className="category-desc">
      생각하시는 사진이나 도면이 있을 경우 이미지를 <br />
      <b>직접 업로드</b> 해주세요 없을 경우 <b>샘플에서 골라</b>
      주세요
    </p>
  )
}
interface DesignContentProps {
  exampleImages: string[]
  product: Product
}
export const DesignContent = ({
  exampleImages,
  product,
}: DesignContentProps) => {
  const [isDesignFree, setIsDesignFree] = useState(false)

  useEffect(() => {
    if (product && product.name === '자유형') {
      setIsDesignFree(true)
    }
  }, [product])
  let productName = ''
  if (product) {
    if (product.product_group && product.product_group !== 'None') {
      productName = `${product.product_group} ${product.name}`
    } else {
      productName = `${product.name}`
    }
  }

  return (
    <>
      {isDesignFree ? (
        <>
          <DesingFreeNoti />
          <OptionContent
            optionType="Photo"
            message="직접 업로드 할게요"
            imgs={exampleImages}
            price={-1}
          />
        </>
      ) : (
        <OptionContent
          optionType={'product'}
          message={productName}
          imgs={exampleImages}
          price={product?.value}
        />
      )}
    </>
  )
}
