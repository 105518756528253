import { DividerLine } from "../../ProductSequence/Format";
import QuickNoti from "./QuickNoti";

export default function QuickInput({
    contentWidth,
    onClickQuickService,
    quickRequired,
    color1,
    quickTime,
    onChangeQuickTime
}: any) {

    return (<>
        <div
            style={{
                width: contentWidth - 50,
                marginLeft: 25,
                marginBottom: 150
            }}>
            <DividerLine contentWidth={contentWidth} />
            <div style={{ display: 'flex' }}>
                <input
                    onClick={onClickQuickService}
                    checked={quickRequired}
                    style={{ accentColor: color1 }}
                    className='radio-button-time' type="radio" />
                <div className={quickRequired ? 'quick-legend' : 'quick-legend-disabled'}>
                    퀵 배달로 받을래요
                </div>
            </div>
            {quickRequired ? <>
                <input
                    onChange={onChangeQuickTime}
                    style={{ width: contentWidth - 50 }}
                    placeholder='케이크 도착 희망시간 입력' value={quickTime} type="text" className='quick-input' />
                <QuickNoti />
            </> : null}
        </div > </>)
}