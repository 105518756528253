import { memo } from "react"
import { useNavigate, useParams } from "react-router-dom"
const DesignCard = ({ product }: any) => {
    const nav = useNavigate()
    const { shopName, orderPk } = useParams();
    const onClickCard = () => {
        if (product.sold_out) {
            return
        }
        sessionStorage.setItem('lastPosition', window.scrollY.toString())
        nav(`/order/${shopName}/${orderPk}/productDetail/${product.id}`)
    }

    let image = "https://imagedelivery.net/OsJkSw9Sjt8i9jgP9HvzGw/aa080608-d89d-4d7f-39cf-1c41bfc22c00/public"
    if (product && product.example_photos.length !== 0) {
        image = (product.example_photos[0].src)
    }

    return (
        <div className='design-card'>
            <img
                style={{ opacity: product.sold_out ? 0.4 : 1 }}
                className='card-image'
                onClick={onClickCard}
                src={image}
            />
            <div >
                <span
                    style={{ opacity: product.sold_out ? 0.2 : 1 }}
                >
                    {product.name}
                </span>

            </div>
        </div>
    )
}


export default memo(DesignCard)