import { useEffect, useRef, useState, useCallback } from 'react'
import dayjs from 'dayjs'
import { CalculatorFromJson } from './calculator'
import { DAY_MAP } from '../../constants/constants'
import { Option } from '../OrderSequence/type'
import { Product } from '../OrderSequence/type'
interface optionRes {
  [key: string]: any
}
export const getCurrentPage = (arg: string) => {
  const pages = JSON.parse(sessionStorage.getItem('pages') as string)
  return pages.find((page: any) => page.option_type === arg.toLowerCase())
}
export const getNextPage = (currentPage: any) => {
  const pages = JSON.parse(sessionStorage.getItem('pages') as string)
  return pages.find((page: any) => page.order === currentPage.order + 1)
}
export const getPrevPage = (currentPage: any) => {
  const pages = JSON.parse(sessionStorage.getItem('pages') as string)
  console.log(currentPage)
  return pages.find((page: any) => page.order === currentPage.order - 1)
}
export const getPageByOptionType = (optionType: any) => {
  const pages = JSON.parse(sessionStorage.getItem('pages') as string)
  return pages.find((page: any) => page.option_type === optionType)
}
export const CheckSelected = (option: any, selected: any) => {
  if (selected) {
    if (option.name === selected.name) {
      return true
    }
  }
  return false
}

export const MoveToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'auto',
  })
}
export const CalculatePrice = (exception: string) => {
  let storage = sessionStorage
  // Date, Time 이 아닌 모든 페이지의 selection들의 value들 중에서 exception을 제외한 것들끼리 더한다.

  const pages = JSON.parse(storage.getItem('pages') as string)
  let sum = 0
  pages.forEach((page: any) => {
    if (!['date', 'time', exception].includes(page.option_type)) {
      let option = JSON.parse(storage.getItem(page.option_type) as string)
      if (option) {
        sum += option.value
      }
    }
  })

  return sum
}

export const GetFilterFromOption = (data: any) => {
  if (data.information && data.information.hasOwnProperty('filter')) {
    return data.information.filter
  }
  return null
}
export const FilterBySelectedOption = (
  optionData: Option[],
  arg: string,
  selectedOptions: Option[],
) => {
  let pkList: number[] = []
  selectedOptions.forEach((option: Option) => {
    const filter = GetFilterFromOption(option)
    if (filter) {
      Object.keys(filter).forEach((filterKey: string) => {
        if (filterKey === arg) {
          pkList = [...pkList, ...filter[filterKey]]
        }
      })
    }
  })

  if (pkList.length !== 0) {
    return optionData.filter((item: any) => pkList.includes(item.id))
  } else {
    return optionData
  }
}
const GetMinPrice = (options: Option[], filterOptionName: string = 'none') => {
  let optionMinPrice = 999999
  const checkCalculate = (option: Option) => {
    if (typeof option.information === 'object') {
      return !('preventCalculate' in option.information)
    } else {
      return true
    }
  }
  options.forEach((option: Option) => {
    if (
      checkCalculate(option) &&
      option.value <= optionMinPrice &&
      option.name !== filterOptionName
    ) {
      optionMinPrice = option.value
    }
  })
  return optionMinPrice
}
export const CalculateDesignMinPrice = (option: Option) => {
  // 디자인 가격 + 최소 사이즈 가격
  const optionData = JSON.parse(sessionStorage.getItem('options') as string)
  let minPrice = option.value
  if ('optionsToAdd' in option.information) {
    const optionsToAdd = option.information.optionsToAdd
    optionsToAdd.forEach((optionType: string) => {
      const options = optionData.filter(
        (item: any) => item.option_type === optionType,
      )
      const OptionsFiltered = FilterBySelectedOption(options, optionType, [
        option,
      ])
      if (OptionsFiltered) minPrice += GetMinPrice(OptionsFiltered)
    })
  } else {
    const options = optionData.filter(
      (item: any) => item.option_type === 'size',
    )
    const OptionsFiltered = FilterBySelectedOption(options, 'size', [option])
    if (OptionsFiltered) minPrice += GetMinPrice(OptionsFiltered)
  }

  return minPrice
}
export const CalculateProductGroupMinPrice = (groupName: string) => {
  // 디자인 그룹에서 디자인 최소 가격 + 최소 사이즈 가격
  const productData = JSON.parse(sessionStorage.getItem('products') as string)
  const optionData = JSON.parse(sessionStorage.getItem('options') as string)
  let arg = ''
  if (groupName === '숫자 케이크') {
    arg = 'number'
  } else {
    arg = 'size'
  }
  const productsInGroup = productData.filter(
    (item: Product) => item.product_group == groupName,
  )

  const options = optionData.filter((item: any) => item.option_type === arg)

  const OptionsFiltered = FilterBySelectedOption(options, arg, [
    productsInGroup[0],
  ])
  let sizeMinPrice = 0
  if (OptionsFiltered) {
    sizeMinPrice = GetMinPrice(OptionsFiltered, '숫자 없음')
  }
  let productMinPrice = GetMinPrice(productsInGroup)

  return productMinPrice + sizeMinPrice
}
export const FilterSortOption = (optionData: any, arg: string) => {
  const optionDataByArg = optionData.filter(
    (item: any) => item.option_type === arg,
  )

  const product = JSON.parse(sessionStorage.getItem('product') as string)
  const size = JSON.parse(sessionStorage.getItem('size') as string)
  let selectedOption = []
  if (product) {
    selectedOption.push(product)
  }
  if (size) {
    selectedOption.push(size)
  }
  const optionDataByArgFiltered = FilterBySelectedOption(
    optionDataByArg,
    arg,
    selectedOption,
  )

  if (!optionDataByArgFiltered) return []
  const tempSort = optionDataByArgFiltered.sort(function (
    a: Option,
    b: Option,
  ) {
    if (a.order > b.order) {
      return 1
    } else if (a.order === b.order) {
      return 0
    } else {
      return -1
    }
  })
  return tempSort
}

export function useHorizontalScroll() {
  const [isTouchMoved, setIsTouchMoved] = useState(false)
  const [touchXState, setTouchXState] = useState(0)
  const [touchStartXState, setTouchStartXState] = useState(0)
  const handleTouchStart = (event: any) => {
    setTouchStartXState(event.touches[0].clientX)
    setTouchXState(event.touches[0].clientX)
  }

  const handleTouchMove = (event: any) => {
    setTouchXState(prev => event.touches[0].clientX)
    // const moveAmount = 200

    // if (xCurrent < touchState.touchXPosition) {
    //   console.log("1")
    //   el.scrollTo({
    //     left: el.scrollLeft + moveAmount,
    //     behavior: "smooth"
    //   });
    // } else {
    //   console.log("2")
    //   el.scrollTo({
    //     left: el.scrollLeft - moveAmount,
    //     behavior: "smooth"
    //   });
    // }
    // setTouchState(prevState => ({ ...prevState, touchXPosition: xCurrent, isDragging: true }));
  }
  const handleTouchEnd = (event: any) => {
    setIsTouchMoved(true)
  }
  const elRef: any = useRef()
  useEffect(() => {
    const el = elRef.current
    if (isTouchMoved) {
      if (el) {
        const moveAmount = 200
        if (touchStartXState < touchXState) {
          el.scrollTo({
            left: el.scrollLeft + moveAmount,
            behavior: 'smooth',
          })
        } else {
          el.scrollTo({
            left: el.scrollLeft - moveAmount,
            behavior: 'smooth',
          })
        }
        setTouchStartXState(0)
        setTouchXState(0)
        el.addEventListener('touchstart', handleTouchStart)
        el.addEventListener('touchmove', handleTouchMove)
        el.addEventListener('touchend', handleTouchEnd)
        return () => {
          setIsTouchMoved(false)
          el.removeEventListener('touchstart', handleTouchStart)
          el.removeEventListener('touchmove', handleTouchMove)
          el.removeEventListener('touchend', handleTouchEnd)
        }
      }
    }
  }, [isTouchMoved])
  useEffect(() => {
    const el = elRef.current
    if (el) {
      const onWheel = (e: any) => {
        if (e.deltaY == 0) return
        e.preventDefault()
        el.scrollTo({
          left: el.scrollLeft + e.deltaY,
          behavior: 'smooth',
        })
      }
      el.addEventListener('wheel', onWheel)
      return () => {
        el.removeEventListener('wheel', onWheel)
      }
    }
  }, [])
  return elRef
}

export const parseTime = (timeString: string | null) => {
  if (!timeString) {
    return dayjs().set('hour', 10)
  }

  const [hour, minute, second] = timeString.split(':').map(Number)
  return dayjs()
    .set('hour', hour)
    .set('minute', minute)
    .set('second', second)
    .set('ms', 0)
}

export function mapBlockedDaysNumberToDay(
  blockedDay: number[] | null,
): string[] | null {
  if (!blockedDay) {
    return null
  }
  const blockedDayStrings = blockedDay.map(dayNumber => DAY_MAP[dayNumber])
  return blockedDayStrings
}
