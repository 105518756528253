import { useNavigate, useParams } from 'react-router-dom'
import { getPageByOptionType, getPrevPage } from '../../utility/utils'

export default function ProgressBar({
  checkMode,
  contentWidth,
  orderIndex,
}: any) {
  const barWidth = contentWidth - 79
  const stepWidth = barWidth / 10
  const { shopName, orderPk, optionType, viewType } = useParams()

  const nav = useNavigate()

  const clickBackward = () => {
    // Product 선택에서 뒤로가기를 누르면 ProductDetail로 넘어가는 이슈 때문에 아래 코드가 추가됨
    // 정상 작동 위해서는 ProductDetail이 페이지가 아닌 컴포넌트가 되어야 한다.
    // 안드로이드 유저들의 뒤로가기 액션 때문에 ProductDetail이 페이지로 독립되어 있어서 이를 당분간은 수정하지 않는다.
    if (
      window.location.href.includes('product') &&
      !window.location.href.includes('productDetail')
    ) {
      nav(`/order/${shopName}/${orderPk}/Time`)
    } else if (window.location.href.includes('Time')) {
      nav(`/order/${shopName}/${orderPk}/Date`)
    } else if (window.location.href.includes('Date')) {
      nav(`/order/${shopName}/${orderPk}`)
    } else {
      nav(-1)
    }
  }
  const barBaseStyle = {
    height: '4px',
    width: barWidth,
    background: '#EFF1ED',
    borderRadius: '100px',
    marginLeft: 19,
  }

  const backArrowStyle = {
    width: '11px',
    height: '21px',
  }
  return (
    <div>
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: contentWidth,
          height: '13.5px',
          background: 'white',
          zIndex: 10,
        }}
      ></div>
      {checkMode ? (
        <div
          style={{
            position: 'fixed',
            paddingLeft: '25px',
            paddingRight: '25px',
            width: contentWidth,
            top: 13.5,
            height: 45,
            background: 'white',
            zIndex: 10,
          }}
        ></div>
      ) : (
        <div
          style={{
            position: 'fixed',
            paddingLeft: '25px',
            paddingRight: '25px',
            width: contentWidth,
            top: 13.5,
            height: 45,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            background: 'white',
            zIndex: 10,
          }}
        >
          {orderIndex === 9 ? (
            <div></div>
          ) : (
            <img
              onClick={clickBackward}
              style={backArrowStyle}
              src="/back.png"
            />
          )}
          <div style={barBaseStyle}>
            <div
              style={{
                position: 'absolute',
                width: stepWidth * orderIndex,
                height: '4px',
                background: '#2D2D2D',
                borderRadius: '100px',
              }}
            ></div>
          </div>
        </div>
      )}
    </div>
  )
}
