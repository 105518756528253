import '../../css/Product/Format.scss'

export function ProductDivider({ contentWidth }: any) {
  return (
    <div
      className="product-divider"
      style={{
        width: contentWidth,
      }}
    ></div>
  )
}
export function DividerLine({ contentWidth }: any) {
  return (
    <div
      className="line-divider"
      style={{
        width: contentWidth,
      }}
    ></div>
  )
}
