import { useMutation } from '@tanstack/react-query'
import { colorCharcoal } from '../components/OrderSequence/Constants'
import '../css/OrderCheck.scss'

import { useState, useEffect } from 'react'
import { getGPTMessage } from '../api'

export function SubmitButton({ message, onClick, color }: any) {
  return (
    <button
      onClick={onClick}
      style={{
        background: color,
      }}
      className="button_next_intro_simple"
    >
      <span>{message}</span>
    </button>
  )
}
const CustomizeMessage = () => {
  let placeHolder = '원영적 사고의 힘을 받아라 얍'

  let storage = sessionStorage
  const [customMessageResult, setCustomMessageResult] = useState('')
  const [messageSet, isMessageSet] = useState(false)

  const customMessage = storage.getItem('10message')
  useEffect(() => {
    if (customMessage && !messageSet) {
      setCustomMessageResult(customMessage)
      isMessageSet(true)
    }
  }, [customMessage])
  const onChangeCustomMessage = (e: any) => {
    setCustomMessageResult(e.target.value)
    storage.setItem('10message', e.target.value as string)
  }
  const [isWriting, setIsWriting] = useState(false)

  const textAreaStyle = {
    height: isWriting ? '240px' : '105px',
  }

  useEffect(() => {
    if (customMessageResult && customMessageResult.length > 0) {
      setIsWriting(true)
    } else {
      setIsWriting(false)
    }
  }, [customMessageResult])
  return (
    <div>
      <textarea
        placeholder={placeHolder}
        className="text-area-style"
        onChange={onChangeCustomMessage}
        value={customMessageResult as string}
        style={textAreaStyle}
      ></textarea>
    </div>
  )
}
export default function GPT() {
  const [gptResponse, setGptResponse] = useState('')
  let storage = sessionStorage
  const uploadMessageMutation = useMutation(getGPTMessage, {
    onSuccess: (response: any) => {
      storage.setItem('gpt_response', response)
      setGptResponse(response)
    },
  })
  const onClickSubmit = () => {
    let message = storage.getItem('10message')
    uploadMessageMutation.mutate({ message })
  }
  return (
    <>
      <div style={{ paddingTop: 20, paddingLeft: 20, paddingRight: 20 }}>
        <h1>원영적 사고</h1>
        <CustomizeMessage />

        <SubmitButton
          message={'전송'}
          color={colorCharcoal}
          onClick={onClickSubmit}
        />
        <textarea
          className="text-area-style"
          value={gptResponse}
          style={{
            marginTop: 30,
            height: 105,
          }}
        ></textarea>
      </div>
    </>
  )
}
