
import { useState, useEffect } from "react"
import { Option, Product } from "../type"
import { Address } from "../Address/PostCode"
import { CalculatePrice, FilterBySelectedOption, FilterSortOption, GetFilterFromOption } from "../../utility/utils"
import { UserInfo } from "./OrderContent/UserInfo"
import { DateTime } from "./OrderContent/DateTme"
import { OptionTitle } from "./OrderContent/OptionTitle"
import { OptionContent } from "./OrderContent/OptionContent"
import { CustomizeMessage } from "./OrderContent/CustomMessage"
import { FinishButtonWithPrice } from "../PageElement/NextButton"
import { DesignContent } from "./OrderContent/DesignContent"
import { OrderCheckManager } from "../../utility/orderCheckManager"


interface CakeOrderSheetProps {
    onLoadPage: Function;
    setClipBoardCopyLoaded: React.Dispatch<React.SetStateAction<any>>;
}
export function CakeOrderSheet({
    onLoadPage,
    setClipBoardCopyLoaded,
}: CakeOrderSheetProps) {
    sessionStorage.setItem('checkMode', '1')
    let storage = sessionStorage;
    const contentWidth = Number(storage.getItem('contentWidth'))

    const exampleImages = JSON.parse(storage.getItem('images') as string)
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const pages = JSON.parse(storage.getItem("pages") as string)

    const orderCheckManager = new OrderCheckManager(pages)
    let anyVacant = orderCheckManager.checkIsSomethingVacant()
    const productOption = orderCheckManager.getProduct()
    const optionDisplayList = orderCheckManager.getDsiplayList()

    let quickRequired = false;

    const price = orderCheckManager.getTotalPrice()

    const onClickSubmit = () => {
        if (exampleImages && exampleImages.length !== 0) {
            storage.setItem('link', exampleImages[0])
        } else {
            storage.setItem('link', '')
        }
        setClipBoardCopyLoaded(true)
    }

    const [address, setAddress] = useState<Address>({
        우편번호: "",
        기본주소: "",
        상세주소: "",
    });

    useEffect(() => {
        if (anyVacant) {
            setButtonDisabled(true)
        } else {
            if (quickRequired) {
                if (address.기본주소 && address.상세주소) {
                    setButtonDisabled(false)
                } else {
                    setButtonDisabled(true)
                }
            } else {
                setButtonDisabled(false)
            }
        }
    }, [
        quickRequired, address, anyVacant
    ])
    return (
        <>
            <UserInfo />
            <DateTime
                address={address}
                setAddress={setAddress}
            />
            <div>
                <OptionTitle title={'원하는 디자인'} mandatory={true} />
                <DesignContent
                    exampleImages={exampleImages}
                    product={productOption as Product}
                />
            </div>
            {optionDisplayList?.map((value: any) => (
                <div key={value.title}>
                    <OptionTitle title={value.title} mandatory={true} />
                    <OptionContent
                        optionType={value.optionType}
                        message={value.message}
                        imgs={[]}
                        price={value.price}
                        isVacant={value.isVacant}
                    />
                </div>
            )
            )}
            <CustomizeMessage />
            <FinishButtonWithPrice
                onLoad={onLoadPage}
                buttonDisabled={buttonDisabled}
                onClickSubmit={onClickSubmit}
                contentWidth={contentWidth}
                estimatePrice={price.toLocaleString('en-US')}
            />
        </>
    )
}
