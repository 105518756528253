import { useNavigate, useParams } from 'react-router-dom'
import { Product } from '../type'
import { CalculateDesignMinPrice } from '../../utility/utils'
import { useEffect, useRef, useState } from 'react'
import { colorCharcoal, colorGrayOpacity4th } from '../Constants'
import '../../../css/SelectProduct.scss'
import {
  LineBreakedString,
  LineWithOutBreak,
} from '../../utility/LineBreakedString'
import { ProductDivider } from '../../ProductSequence/Format'
interface ProductDescProp {
  contentWidth: number
  onLoadMain: any
  products: Product[]
}
const ProductDescRow = ({ groupTitle, product }: any) => {
  const { shopName, orderPk } = useParams()
  const nav = useNavigate()

  const isSoldOut = product.sold_out
  const onClickImage = () => {
    if (isSoldOut) {
      return
    }
    sessionStorage.setItem('lastPosition', window.scrollY.toString())
    nav(`/order/${shopName}/${orderPk}/productDetail/${product.id}`)
  }

  const [elementHeight, setElementHeight] = useState<number>(0)
  const [elementWidth, setElementWidth] = useState<number>(0)
  const ref = useRef<HTMLDivElement>(null)
  useEffect(() => {
    setElementHeight(ref.current?.clientHeight as number)
    setElementWidth(ref.current?.clientWidth as number)
  }, [ref])

  let minPrice = CalculateDesignMinPrice(product)
  const imageSize = 95
  return (
    <>
      <div
        style={{
          display: 'flex',
          position: 'relative',
        }}
        ref={ref}
        onClick={onClickImage}
      >
        {isSoldOut ? (
          <div
            style={{
              zIndex: 3,
            }}
          >
            <div
              style={{
                width: elementWidth - imageSize,
                height: elementHeight,
                position: 'absolute',
                left: imageSize,
                top: 0,
                backgroundColor: 'white',
                opacity: 0.5,
              }}
            ></div>
            <div
              className="sold-out-message"
              style={{
                position: 'absolute',
                width: imageSize,
                height: imageSize,
                textAlign: 'center',
                paddingTop: 45,
              }}
            >
              품절
            </div>
          </div>
        ) : null}
        <img
          loading="lazy"
          style={{
            display: 'block',
            aspectRatio: '1/1',
            height: imageSize,
            width: imageSize,
            objectFit: 'cover',
            borderRadius: 10,
            marginRight: 15,
            filter: isSoldOut ? 'brightness(50%)' : 'brightness(100%)',
          }}
          src={product.example_photos[0].src}
        />
        <div
          className="product-option"
          style={{
            width: '100%',
            height: 95,
            display: 'flex',
            marginRight: 20,
            alignItems: 'start',
            flexDirection: 'column',
          }}
        >
          <div className="product-title-row">
            <div className="product-title">{groupTitle}</div>
            {'label' in product.information ? (
              <div
                className="product-label"
                style={{
                  color: colorCharcoal,
                  backgroundColor: colorGrayOpacity4th,
                }}
              >
                {product.information.label}
              </div>
            ) : null}
          </div>

          <div className="product-desc">{LineWithOutBreak(product.desc)}</div>
          <div className="product-price" style={{ color: colorCharcoal }}>
            {`${minPrice.toLocaleString('en-US')} 원 ~`}
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: imageSize,
          }}
        ></div>
      </div>
    </>
  )
}
export default function ProductDesc({
  contentWidth,
  onLoadMain,
  products,
}: ProductDescProp) {
  return (
    <>
      <div
        style={{
          width: contentWidth,
          paddingLeft: 20,
          paddingRight: 20,
          marginTop: 40,
          marginBottom: 150,
        }}
        onLoad={onLoadMain}
      >
        {products?.map((product: Product) => (
          <>
            <ProductDescRow
              key={product.name + product.product_group}
              groupTitle={product.name}
              product={product}
            />
            <ProductDivider />
          </>
        ))}
      </div>
    </>
  )
}
