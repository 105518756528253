export const LineBreakedString = (_title: string) => {
  if (_title.includes('_')) {
    const titleList = _title.split('_')
    return (
      <div>
        {titleList[0]}
        <br />
        {titleList[1]}
      </div>
    )
  } else {
    return <div>{_title}</div>
  }
}

export const LineWithOutBreak = (_title: string) => {
  return _title.replace('_', ' ')
}
