import { Product } from '../type'
import { useLocation } from 'react-router-dom'
import DesignGrid from '../PageLayout/DesignGrid'
import { useCallback } from 'react'
interface ProductTileProps {
  contentWidth: number
  products: Product[]
  productSelected: Product
}
export default function ProductTile({
  contentWidth,
  products,
  productSelected,
}: ProductTileProps) {
  const checkIsSelected = useCallback(
    (option: any) => {
      if (productSelected) {
        return (
          option.name === productSelected.name &&
          option.option_group === productSelected.product_group
        )
      }
      return false
    },
    [productSelected],
  )

  const location = useLocation()
  const onLoadMain = () => {
    if (location.state && location.state.prevPath === 'productDetail') {
      let lastPosition = Number(sessionStorage.getItem('lastPosition'))
      window.scrollTo({
        top: lastPosition,
        behavior: 'instant' as ScrollBehavior,
      })
      window.history.replaceState({}, document.title)
    }
  }
  return (
    <>
      <div onLoad={onLoadMain} style={{ marginTop: 32, marginBottom: 150 }}>
        <DesignGrid
          contentWidth={contentWidth}
          products={products}
          designGroupName={''}
          checkIsSelected={checkIsSelected}
        />
      </div>
    </>
  )
}
